// src/components/PricingSection.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import NewsletterSection from '../../../../Components/NewsLetter/NewsLetter';
import { PricingCardSection } from './components/PricingCardSection';
import { PlanComparisonTable } from './components/PlanComparisonTable';
import { FAQSection } from './components/FAQSection';

const PricingSection = () => {
    const navigate = useNavigate();
    return (
        <>
            {/* Meta Tags for SEO and Social Sharing */}
            <Helmet>
                <title>Pricing ─ Asendia AI</title>
                <meta
                    name="description"
                    content="Explore our flexible pricing plans tailored to meet your recruitment needs. Choose the best plan for your business and start optimizing your hiring process with Asendia AI today."
                />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Pricing - Asendia AI" />
                <meta
                    property="og:description"
                    content="Explore our flexible pricing plans tailored to meet your recruitment needs. Choose the best plan for your business and start optimizing your hiring process with Asendia AI today."
                />
                <meta property="og:image" content="https://www.asendia.ai/images/social_sharing_banner2.png"/>
                <meta property="og:url" content="https://asendia.ai/pricing" />
                <meta property="og:site_name" content="Asendia AI" />

                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Pricing - Asendia AI" />
                <meta
                    name="twitter:description"
                    content="Explore our flexible pricing plans tailored to meet your recruitment needs. Choose the best plan for your business and start optimizing your hiring process with Asendia AI today."
                />
                <meta name="twitter:image" content="https://www.asendia.ai/images/social_sharing_banner2.png" />

                {/* Canonical Link */}
                <link rel="canonical" href="https://asendia.ai/pricing" />

                {/* JSON-LD Structured Data */}
                <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "WebPage",
                            "name": "Pricing",
                            "url": "https://asendia.ai/pricing",
                            "description": "Explore our flexible pricing plans tailored to meet your recruitment needs. Choose the best plan for your business and start optimizing your hiring process with Asendia AI today."
                        }
                    `}
                </script>
            </Helmet>

            <div className="pb-16 md:pb-20 max-w-[94%] mx-auto px-4 py-10 sm:px-6 lg:px-8 lg:py-6">
                {/* Pricing Cards Section */}
                <PricingCardSection />

                {/* Divider */}
                <div className="my-2 w-[80%] mx-auto h-[1px] bg-gray-200/0"></div>

                {/* Plan Comparison Table */}
                <PlanComparisonTable />

                {/* Payment Icons and Security Information */}
                <div className="flex flex-col items-center justify-center space-y-4 py-8 mt-8">
                    {/* Payment Icons */}
                    <div className="flex flex-wrap items-center justify-center gap-4 sm:gap-6">
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png"
                            alt="Visa"
                            className="h-5 sm:h-6 object-contain"
                        />
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/0/04/Mastercard-logo.png"
                            alt="Mastercard"
                            className="h-5 sm:h-6 object-contain"
                        />
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/f/fa/American_Express_logo_%282018%29.svg"
                            alt="American Express"
                            className="h-5 sm:h-6 object-contain"
                        />
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg"
                            alt="PayPal"
                            className="h-5 sm:h-6 object-contain"
                        />
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/5/57/Discover_Card_logo.svg"
                            alt="Discover"
                            className="h-3 sm:h-4 object-contain"
                        />
                    </div>

                    {/* Security Text */}
                    <p className="text-xs sm:text-sm text-gray-600 text-center px-4">
                        For your security, all orders are processed via Stripe.
                    </p>
                </div>

                {/* Divider */}
                <div className="my-2 w-[80%] mx-auto h-[1px] bg-gray-200/0"></div>

                {/* FAQ Section */}
                <FAQSection />

                {/* Footer Note (Taxes, Contact) */}
                <div className="mt-6 md:mt-10 text-center">
                    <p className="mb-5 text-xs sm:text-sm text-gray-600">
                        Standard local taxes and VAT may apply, following the laws of your country.
                    </p>
                    <div className="flex flex-wrap justify-center items-center gap-2">
                        <svg
                            className="shrink-0 size-5 text-gray-600"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <circle cx="12" cy="12" r="10"></circle>
                            <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                            <path d="M12 17h.01"></path>
                        </svg>
                        <h5 className="text-sm font-medium text-gray-800">
                            Have pricing or license questions?
                        </h5>
                        <button
                            type="button"
                            className="inline-flex items-center gap-x-1 text-sm font-medium text-indigo-600 decoration-2 hover:underline focus:outline-none focus:underline"
                            onClick={() => navigate('/talk-to-founders')}
                        >
                            Contact us
                            <svg
                                className="shrink-0 size-4"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path d="m9 18 6-6-6-6"></path>
                            </svg>
                        </button>
                    </div>
                </div>

                {/* Dividers */}
                <div className="my-10 w-[80%] mx-auto h-[1px] bg-gray-200/0"></div>
                <div className="my-10 w-[80%] mx-auto h-[1px] bg-gray-200/0"></div>

                {/* Newsletter Section */}
                <div className='max-w-[83%] mx-auto'>

                
                <NewsletterSection />
                </div>
            </div>
        </>
    );

};

export default PricingSection;
