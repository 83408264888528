// src/components/AsendiaAiPartnerForm.jsx

import { useState } from 'react';
import { AiFillDownSquare } from 'react-icons/ai';
import { FaArrowLeft, FaCheckCircle } from 'react-icons/fa'; // Importing check icon for success message
import asendialogo from "../../../assets/asendia_white_logo.png";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css"; // Importing CSS for phone input
import { storeWaitlistUserData } from '../../../service/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import react-toastify CSS
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion'; // Import Framer Motion
import { TbArrowLeftFromArc } from 'react-icons/tb';

export default function AsendiaAiPartnerForm() {
    // Initial form data state
    const initialFormData = {
        firstName: '',
        lastName: '',
        country: '',
        emailAddress: '',
        phoneNumber: '',
        usageType: '',
        companySector: '',
        companySize: '',
        subscribeNewsletter: true
    };

    const [formData, setFormData] = useState(initialFormData);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false); // New state for submission status

    // List of free email domains for business email verification
    const freeEmailDomains = [
        'gmail.com',
        'yahoo.com',
        'hotmail.com',
        'outlook.com',
        'icloud.com',
        'aol.com',
        'mail.com',
        'zoho.com',
        'protonmail.com',
        'gmx.com',
        'yandex.com',
        'live.com',
        'msn.com',
        'comcast.net',
        'me.com',
        'sbcglobal.net',
        'att.net',
        'verizon.net',
        'qq.com',
        'yahoo.co.uk',
        'hotmail.co.uk',
        // Add more free email domains as needed
    ];

    // Custom styles for the progress bar and icon
    const customStylesSuccess = {
        "--toastify-color-progress-success": "#6366f1", // Indigo for progress bar
        "--toastify-icon-color-success": "#6366f1", // Indigo for the success icon
        width: "400px", // Increase width of the toast
    };

    const customStylesError = {
        // Error toast styles
        "--toastify-color-progress-error": "#dc2626", // Red for progress bar
        "--toastify-icon-color-error": "#dc2626", // Red for the error icon
        width: "500px", // Increase width of the toast
    };

    // Handler for text inputs
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // Handler for phone number input
    const handlePhoneChange = (value) => {
        setFormData((prev) => ({ ...prev, phoneNumber: value }));
    };

    // Handler for checkbox changes
    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        setFormData((prev) => {
            if (checked) {
                return { ...prev, usageType: value };
            } else {
                return { ...prev, usageType: '' };
            }
        });
    };

    // Function to check if email is a business email
    const isBusinessEmail = (email) => {
        const domain = email.split('@')[1]?.toLowerCase();
        return domain && !freeEmailDomains.includes(domain);
    };

    // Submit handler with validation
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Basic validation for required fields
        if (!formData.firstName.trim()) {
            toast.error('First Name is required.', { position: "bottom-left" });
            return;
        }
        if (!formData.lastName.trim()) {
            toast.error('Last Name is required.', { position: "bottom-left" });
            return;
        }
        if (!formData.country.trim()) {
            toast.error('Country is required.', { position: "bottom-left" });
            return;
        }
        if (!formData.emailAddress.trim()) {
            toast.error('Email Address is required.', { position: "bottom-left" });
            return;
        }
        // Simple email format validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.emailAddress)) {
            toast.error('Please enter a valid email address.', { position: "bottom-left" });
            return;
        }
        // Business email verification
        if (!isBusinessEmail(formData.emailAddress)) {
            toast.error('Please enter a business email address.', { position: "bottom-left" });
            return;
        }
        if (!formData.phoneNumber) {
            toast.error('Phone Number is required.', { position: "bottom-left" });
            return;
        }
        if (!formData.usageType) {
            toast.error('Please select a Usage Type.', { position: "bottom-left" });
            return;
        }
        if (!formData.companySector) {
            toast.error('Please select a Company Sector.', { position: "bottom-left" });
            return;
        }
        if (!formData.companySize) {
            toast.error('Please select a Company Size.', { position: "bottom-left" });
            return;
        }

        setIsSubmitting(true);

        try {
            const response = await storeWaitlistUserData(formData);
            console.log('API Response:', response);

            toast.success('Waitlist form submitted successfully!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                style: customStylesSuccess, // Apply custom styles
            });
            // Reset the form after successful submission
            setFormData(initialFormData);
            setIsSubmitted(true); // Update submission status
        } catch (error) {
            console.error('Error submitting form data:', error);
            toast.error(error.message || 'An error occurred while submitting the form.', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                style: customStylesError, // Apply custom styles
            });
        } finally {
            setIsSubmitting(false);
        }
    };


    const navigate = useNavigate();
    const location = useLocation();

    const handleBack = () => {
        if (location.key !== 'default') {
            navigate(-1); // Go to the previous route
        } else {
            // Handle when no back route exists, like navigating to a default page
            navigate('/');
        }
    };


    // Define Framer Motion variants
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2,
            },
        },
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { 
            opacity: 1, 
            y: 0,
            transition: {
                type: 'spring',
                stiffness: 50,
                damping: 20,
            },
        },
    };

    const buttonVariants = {
        hover: {
            scale: 1,
            opacity: 0.8, // Sets opacity to 90% on hover
            boxShadow: '0px 10px 20px rgba(132, 132, 132, 0.11)',
            transition: {
                yoyo: Infinity, // Keeps the transition repeating
            },
        },
        tap: {
            scale: 0.95,
        },
    };
    

    return (
        <>
            <ToastContainer />
            <motion.div
                className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-white via-gray-100 via-white to-indigo-50 px-4 py-14 !pb-[6.5rem]"
                initial="hidden"
                animate="visible"
                variants={containerVariants}
            >

                {/* Back Button */}
                <motion.div
                    className="max-w-5xl w-full flex justify-start mb-4 -mt-4"
                    variants={itemVariants}
                >
                    <button
                        onClick={handleBack}
                        className="p-2 bg-white border-2 rounded-full text-gray-800 hover:text-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transition ease-in-out duration-300"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                    >
                        <FaArrowLeft className="w-6 h-6" />
                    </button>
                </motion.div>

                {/* Form */}
                <motion.form
                    onSubmit={handleSubmit}
                    className="border max-w-2.5xl w-full bg-white shadow-2xl rounded-3xl p-8 sm:p-12 mt-2"
                    variants={itemVariants}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.2 }}
                >
                    {/* Page header */}
                    <motion.div
                        className="relative bg-gradient-to-br from-purple-800 rounded-3xl bg-gradient-to-r from-[#31244d] via-[#1b1231] to-[#251A3D] text-white py-10 px-6 sm:px-10 lg:px-20 mb-8"
                        variants={itemVariants}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.2 }}
                    >
                        {/* Background overlay with subtle gradient */}
                        <div className="absolute inset-0 bg-gradient-to-t rounded-3xl from-[#31244d] to-transparent"></div>

                        {/* Decorative elements (optional) */}

                        {/* Content */}
                        <div className="relative text-center z-10">
                            {/* Logo */}
                            <motion.img
                                src={asendialogo}
                                alt="Asendia Logo"
                                className="max-w-[8rem] sm:max-w-[6rem] mx-auto mb-5 opacity-90 hover:scale-105 transition-transform duration-300 ease-in-out"
                                whileHover={{ scale: 1.05 }}
                                transition={{ duration: 0.3 }}
                            />
                            {/* Main Heading */}
                            <motion.h1
                                className="text-2xl sm:text-3xl font-bold bg-clip-text bg-gradient-to-r from-gray-50 via-indigo-200 to-gray-50 text-transparent tracking-tight"
                                variants={itemVariants}
                            >
                                Get Early Access to Asendia AI
                            </motion.h1>

                            {/* Supporting Text */}
                            <motion.p
                                className="mt-4 text-md font-thin sm:text-md text-gray-200 max-w-md mx-auto"
                                variants={itemVariants}
                            >
                                Be among the first to experience our innovative AI-driven solutions. Fill out the form below to join our early access program.
                            </motion.p>
                        </div>
                    </motion.div>

                    {/* Conditional Rendering: Show Success Message or Form Fields */}
                    {isSubmitted ? (
                        // Success Message
                        <motion.div
                            className="flex flex-col items-center justify-center text-center space-y-5 py-10 px-6 rounded-lg"
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.5 }}
                        >
                            <FaCheckCircle className="text-indigo-500 w-12 h-12" />
                            <h2 className="text-2xl font-semibold text-gray-800">
                                Thank You for Joining!
                            </h2>
                            <p className="text-gray-600 max-w-md">
                                We’ve successfully received your submission. You’re now on the waitlist and will be notified once early access is available.
                            </p>
                            <motion.button
                                type="button"
                                onClick={() => setIsSubmitted(false)}
                                className="mt-6 px-6 py-3 text-sm font-medium text-white bg-gradient-to-r from-indigo-500 to-indigo-600 rounded-full shadow-lg hover:from-indigo-600 hover:to-indigo-700 focus:outline-none focus:ring-4 focus:ring-indigo-300 transition-transform duration-300 transform hover:scale-105"
                                variants={buttonVariants}
                                whileHover="hover"
                                whileTap="tap"
                            >
                                Submit Another Response
                            </motion.button>
                        </motion.div>

                    ) : (
                        // Form Fields
                        <motion.div
                            className="space-y-6"
                            variants={containerVariants}
                        >
                            {/* First Name */}
                            <motion.div
                                variants={itemVariants}
                            >
                                <label
                                    htmlFor="firstName"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    First Name <span className="text-red-500">*</span>
                                </label>
                                <input
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    required
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    className="mt-2 block w-full rounded-2xl border border-gray-300 bg-white px-4 py-3 text-gray-800 placeholder-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 shadow-sm transition-all duration-200"
                                    placeholder="John"
                                />
                            </motion.div>

                            {/* Last Name */}
                            <motion.div
                                variants={itemVariants}
                            >
                                <label
                                    htmlFor="lastName"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Last Name <span className="text-red-500">*</span>
                                </label>
                                <input
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    required
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    className="mt-2 block w-full rounded-2xl border border-gray-300 bg-white px-4 py-3 text-gray-800 placeholder-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 shadow-sm transition-all duration-200"
                                    placeholder="Doe"
                                />
                            </motion.div>

                            {/* Country */}
                            <motion.div
                                variants={itemVariants}
                            >
                                <label
                                    htmlFor="country"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Country <span className="text-red-500">*</span>
                                </label>
                                <div className="relative mt-2">
                                    <select
                                        id="country"
                                        name="country"
                                        required
                                        value={formData.country}
                                        onChange={handleChange}
                                        className="block w-full appearance-none rounded-2xl border border-gray-300 bg-white px-4 py-3 pr-10 text-sm text-gray-800 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 shadow-sm transition-all duration-200"
                                    >
                                        <option value="">-- Select a country --</option>
                                        <option>United States</option>
                                        <option>Canada</option>
                                        <option>United Kingdom</option>
                                        <option>Germany</option>
                                        <option>France</option>
                                        <option>Italy</option>
                                        <option>Spain</option>
                                        <option>Australia</option>
                                        <option>India</option>
                                        <option>Pakistan</option>
                                        <option>China</option>
                                        <option>Japan</option>
                                        <option>South Korea</option>
                                        <option>Brazil</option>
                                        <option>Mexico</option>
                                        <option>South Africa</option>
                                        <option>New Zealand</option>
                                        <option>Netherlands</option>
                                        <option>Sweden</option>
                                        <option>Norway</option>
                                        <option>Denmark</option>
                                        <option>Switzerland</option>
                                        <option>Singapore</option>
                                        <option>United Arab Emirates</option>
                                        <option>Saudi Arabia</option>
                                        <option>Russia</option>
                                        <option>Malaysia</option>
                                        <option>Indonesia</option>
                                        <option>Philippines</option>
                                        <option>Bangladesh</option>
                                        <option>Vietnam</option>
                                        <option>Turkey</option>
                                        <option>Argentina</option>
                                        <option>Chile</option>
                                        <option>Colombia</option>
                                        <option>Egypt</option>
                                        <option>Other</option>
                                    </select>
                                    <AiFillDownSquare
                                        className="pointer-events-none absolute top-3 right-4 h-6 w-6 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </div>
                            </motion.div>

                            {/* Email Address */}
                            <motion.div
                                variants={itemVariants}
                            >
                                <label
                                    htmlFor="emailAddress"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Email Address <span className="text-red-500">*</span>
                                </label>
                                <input
                                    type="email"
                                    id="emailAddress"
                                    name="emailAddress"
                                    required
                                    value={formData.emailAddress}
                                    onChange={handleChange}
                                    className="mt-2 block w-full rounded-2xl border border-gray-300 bg-white px-4 py-3 text-gray-800 placeholder-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 shadow-sm transition-all duration-200"
                                    placeholder="john.doe@yourcompany.com"
                                />
                            </motion.div>

                            {/* Phone Number */}
                            <motion.div
                                variants={itemVariants}
                            >
                                <label
                                    htmlFor="phoneNumber"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Phone Number <span className="text-red-500">*</span>
                                </label>
                                <PhoneInput
                                    placeholder="Enter phone number"
                                    value={formData.phoneNumber}
                                    onChange={handlePhoneChange}
                                    defaultCountry="US"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    required
                                    className="mt-2 block w-full rounded-2xl border border-gray-300 bg-white px-4 py-3 text-gray-800 placeholder-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 shadow-sm transition-all duration-200"
                                />
                            </motion.div>

                            {/* Usage Type */}
                            <motion.div
                                variants={itemVariants}
                            >
                                <label className="block text-sm font-medium text-gray-700">
                                    Usage Type <span className="text-red-500">*</span>
                                </label>
                                <div className="mt-3 flex items-center space-x-6">
                                    <label className="inline-flex items-center text-sm text-gray-700">
                                        <input
                                            type="radio"
                                            name="usageType"
                                            value="Business"
                                            checked={formData.usageType === "Business"}
                                            onChange={handleCheckboxChange}
                                            className="mr-3 h-6 w-6 text-indigo-600 border-gray-300 rounded-md focus:ring-indigo-500"
                                        />
                                        Business
                                    </label>
                                    <label className="inline-flex items-center text-sm text-gray-700">
                                        <input
                                            type="radio"
                                            name="usageType"
                                            value="Personal"
                                            checked={formData.usageType === "Personal"}
                                            onChange={handleCheckboxChange}
                                            className="mr-3 h-6 w-6 text-indigo-600 border-gray-300 rounded-full focus:ring-indigo-500"
                                        />
                                        Personal
                                    </label>
                                </div>
                            </motion.div>

                            {/* Company Sector */}
                            <motion.div
                                variants={itemVariants}
                            >
                                <label
                                    htmlFor="companySector"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Company Sector <span className="text-red-500">*</span>
                                </label>
                                <div className="relative mt-2">
                                    <select
                                        id="companySector"
                                        name="companySector"
                                        required
                                        value={formData.companySector}
                                        onChange={handleChange}
                                        className="block w-full appearance-none rounded-2xl border border-gray-300 bg-white px-4 py-3 pr-10 text-sm text-gray-800 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 shadow-sm transition-all duration-200"
                                    >
                                        <option value="">-- Select a sector --</option>
                                        <option>Technology</option>
                                        <option>Finance</option>
                                        <option>Healthcare</option>
                                        <option>Education</option>
                                        <option>Retail</option>
                                        <option>Manufacturing</option>
                                        <option>Transportation</option>
                                        <option>Energy</option>
                                        <option>Hospitality</option>
                                        <option>Real Estate</option>
                                        <option>Other</option>
                                    </select>
                                    <AiFillDownSquare
                                        className="pointer-events-none absolute top-3 right-4 h-6 w-6 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </div>
                            </motion.div>

                            {/* Company Size */}
                            <motion.div
                                variants={itemVariants}
                            >
                                <label
                                    htmlFor="companySize"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Company Size <span className="text-red-500">*</span>
                                </label>
                                <div className="relative mt-2">
                                    <select
                                        id="companySize"
                                        name="companySize"
                                        required
                                        value={formData.companySize}
                                        onChange={handleChange}
                                        className="block w-full appearance-none rounded-2xl border border-gray-300 bg-white px-4 py-3 pr-10 text-sm text-gray-800 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 shadow-sm transition-all duration-200"
                                    >
                                        <option value="">-- Select company size --</option>
                                        <option>1-10 employees</option>
                                        <option>11-50 employees</option>
                                        <option>51-200 employees</option>
                                        <option>201-500 employees</option>
                                        <option>501-1000 employees</option>
                                        <option>1001+ employees</option>
                                    </select>
                                    <AiFillDownSquare
                                        className="pointer-events-none absolute top-3 right-4 h-6 w-6 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </div>
                            </motion.div>

                            {/* Subscribe to Newsletter */}
                            <motion.div
                                className="!mt-10"
                                variants={itemVariants}
                            >
                                <label className="inline-flex items-center text-sm text-gray-700">
                                    <input
                                        type="checkbox"
                                        name="subscribeNewsletter"
                                        checked={formData.subscribeNewsletter}
                                        onChange={(e) =>
                                            setFormData((prevData) => ({
                                                ...prevData,
                                                subscribeNewsletter: e.target.checked,
                                            }))
                                        }
                                        className="mr-3 h-5 w-5 text-indigo-600 border-gray-300 rounded-2xl focus:ring-indigo-500"
                                    />
                                    Subscribe to Newsletter
                                </label>
                            </motion.div>

                            {/* Submit Button */}
                            <motion.div
                                className="mt-10"
                                variants={itemVariants}
                            >
                                <motion.button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className={`w-full flex justify-center items-center bg-gradient-to-r from-[#45237b] via-[#1b1231] to-[#251A3D] text-white py-4 px-4 rounded-2xl hover:bg-indigo-700 transition-colors duration-300 font-semibold text-lg ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                                        }`}
                                    variants={buttonVariants}
                                    whileHover="hover"
                                    whileTap="tap"
                                >
                                    {isSubmitting ? (
                                        <svg
                                            className="animate-spin h-6 w-6 mr-3 text-white"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            ></circle>
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8v8H4z"
                                            ></path>
                                        </svg>
                                    ) : null}
                                    {isSubmitting ? 'Submitting...' : 'Join Waitlist'}
                                </motion.button>
                            </motion.div>
                        </motion.div>
                    )}
                </motion.form>
            </motion.div>
        </>
    )
}
