// JobsPage.js
import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { JobsData } from './components/JobsData';
import { Zap, Clock, MapPin, ArrowRight, Gift } from 'lucide-react';
// Animation variants for items
const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
};

const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.2 } },
};

// A simple badge component based on job type
const Badge = ({ type }) => {
    const getColorClasses = () => {
        switch (type) {
            case 'Full-time':
                return 'bg-blue-50 text-blue-700 border border-blue-200';
            case 'Part-time':
                return 'bg-green-50 text-green-700 border border-green-200';
            case 'Part & Full-Time':
                return 'bg-purple-50 text-purple-700 border border-purple-200';
            default:
                return 'bg-gray-50 text-gray-700 border border-gray-200';
        }
    };

    return (
        <span className={`w-fit px-3 py-1 rounded-full text-xs font-medium ${getColorClasses()}`}>
            {type}
        </span>
    );
};



const JobCard = ({ job, onApply }) => (
    <div className="group relative bg-white rounded-2xl p-6 shadow-sm hover:shadow-lg transition-all duration-300 border border-gray-100 overflow-hidden">
        {/* Content */}
        <div className="relative z-10">
            {/* Job Title and Badge */}
            <div className="flex justify-between items-start gap-4 mb-6">
                <h2 className="text-xl font-bold text-gray-900">
                    {job.title}
                </h2>
                <Badge type={job.type} />
            </div>

            {/* Job Details */}
            <div className="space-y-4 text-sm mb-6">
                <div className="flex items-center gap-2 text-gray-600">
                    <MapPin className="w-4 h-4 text-gray-500" />
                    <span>{job.location}</span>
                </div>
                <div className="flex items-center gap-2 text-gray-600">
                    <Clock className="w-4 h-4 text-gray-500" />
                    <span>{job.hours}</span>
                </div>
                <p className="text-gray-900 font-medium">
                    {job.salary}
                </p>
            </div>

            {/* Apply Button */}
            <button
                onClick={() => onApply(job.slug, job)}
                className="w-full flex items-center justify-center gap-2 bg-indigo-700 text-white py-2.5 px-4 rounded-xl font-semibold hover:bg-indigo-800 transition-all duration-200  hover:shadow-lg"
            >
                <Zap className="w-5 h-5" />
                <span>Apply Now</span>
                <ArrowRight className="w-4 h-4 ml-1 transition-transform group-hover:translate-x-1" />
            </button>

           
            <button className="mt-3 w-full flex items-center justify-center gap-2 py-3 px-6 rounded-xl font-semibold border-2 border-gray-200 text-gray-800 hover:border-gray-300 hover:bg-gray-100 transition-all">
                  <Gift className="w-5 h-5" />
                  Refer & Earn
                </button>

        </div>

        {/* Glow Effect */}
        <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            <div className="absolute -inset-2 bg-gray-200/20 blur-lg" />
        </div>
    </div>
);

const JobsPage = () => {
    const navigate = useNavigate();


    // When a job card’s button is clicked, navigate to the job detail page passing the job object
    const handleApply = (slug, job) => {
        navigate(`/jobs/${slug}`, { state: { job } });
    };

    return (
        <div className="min-h-screen bg-gray-50 py-16 px-6">
            <motion.div initial="hidden" animate="visible" className="max-w-5xl mx-auto">
                <div className="flex flex-col items-center text-center mb-16 -mt-4">
                    <motion.h2
                        variants={itemVariants}
                        className="text-3xl sm:text-4xl font-bold text-slate-900 mt-2"
                    >
                        Join Asendia AI

                    </motion.h2>
                    <motion.p
                        variants={itemVariants}
                        className="text-gray-600 text-base sm:text-lg mt-4 max-w-lg"
                    >
                        Join our global team of exceptional talent. Work with brilliant minds in Tech, grow, and make an impact in a culture built on collaboration and innovation. Explore our open roles!
                    </motion.p>
                </div>

                <motion.div
                    variants={containerVariants}
                    className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8"
                >
                    {JobsData.map((job, index) => (
                        <motion.div key={index} variants={itemVariants}>
                            <JobCard job={job} onApply={handleApply} />
                        </motion.div>
                    ))}
                </motion.div>
            </motion.div>
        </div>
    );
};

export default JobsPage;
