// AsendiaAiCustomerForm.jsx

import React, { useState } from 'react';
import { AiFillDownSquare } from 'react-icons/ai';
import { FaCheckCircle } from 'react-icons/fa';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'; // Import the CSS for react-phone-number-input
import asendialogo from "../../../../assets/asendia_white_logo.png"; // Adjust the path as needed
import { storeCustomerData } from '../../../../service/Api'; 

export default function JobSeekersPartnerForm() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        country: '',
        age: '',
        gender: '',
        interests: [],
        newsletter: true,
        preferredContact: '',
        feedback: '',
        role: '',
        otherRole: '',
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submissionSuccess, setSubmissionSuccess] = useState(false);
    const [submissionError, setSubmissionError] = useState(null);

    // Handle changes for text, email, number, and textarea inputs
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox' && name === 'newsletter') {
            setFormData((prev) => ({ ...prev, [name]: checked }));
        } else if (type === 'radio' && name === 'role') {
            setFormData((prev) => ({ ...prev, [name]: value, otherRole: '' }));
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
    };

    // Handle changes for checkbox group (interests)
    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        setFormData((prev) => {
            let updated = [...prev.interests];
            if (checked) {
                updated.push(value);
            } else {
                updated = updated.filter((item) => item !== value);
            }
            return { ...prev, interests: updated };
        });
    };

    // Handle changes for phone number input
    const handlePhoneChange = (value) => {
        setFormData((prev) => ({ ...prev, phoneNumber: value }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Basic client-side validation
        if (!formData.phoneNumber) {
            setSubmissionError('Phone number is required.');
            return;
        }

        if (formData.role === 'Other' && !formData.otherRole.trim()) {
            setSubmissionError('Please specify your role.');
            return;
        }

        setIsSubmitting(true);
        setSubmissionSuccess(false);
        setSubmissionError(null);

        try {
            await storeCustomerData(formData);
            setSubmissionSuccess(true);
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                country: '',
                age: '',
                gender: '',
                interests: [],
                newsletter: false,
                preferredContact: '',
                feedback: '',
                role: '',
                otherRole: '',
            });
        } catch (error) {
            setSubmissionError(error.message || 'An error occurred while submitting the form.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-white via-gray-100 via-white to-blue-50 px-4 py-10">
            {submissionSuccess ? (
                <div className="flex flex-col items-center justify-center text-center space-y-5 py-10 px-6 rounded-3xl border bg-white shadow-2xl">
                    <FaCheckCircle className="text-indigo-500 w-12 h-12" />
                    <h2 className="text-2xl font-semibold text-gray-800">
                        Thank You for Joining!
                    </h2>
                    <p className="text-gray-600 max-w-md">
                        We’ve successfully received your information. Stay tuned for updates and exclusive offers!
                    </p>
                    <button
                        type="button"
                        onClick={() => setSubmissionSuccess(false)}
                        className="mt-6 px-6 py-3 text-sm font-medium text-white bg-gradient-to-r from-indigo-500 to-indigo-600 rounded-full shadow-lg hover:from-indigo-600 hover:to-indigo-700 focus:outline-none focus:ring-4 focus:ring-indigo-300 transition-transform duration-300 transform hover:scale-105"
                    >
                        Submit Another Response
                    </button>
                </div>
            ) : (
                <form
                    onSubmit={handleSubmit}
                    className="max-w-4xl w-full bg-white shadow-2xl border rounded-3xl p-8 sm:p-10"
                >
                    {/* Page header */}
                    <div className="relative bg-gradient-to-br from-purple-800 rounded-3xl bg-gradient-to-r from-[#45237b] via-[#1b1231] to-[#251A3D] text-white py-14 px-6 sm:px-10 lg:px-20 mb-12">
                        {/* Background overlay with subtle gradient */}
                        <div className="absolute inset-0 bg-gradient-to-t rounded-3xl from-black/30 to-transparent"></div>

                        {/* Decorative elements (optional) */}
                        <div className="absolute -bottom-10 -right-10 w-40 h-40 bg-gradient-to-br from-blue-500 to-indigo-600 rounded-full opacity-30 blur-3xl"></div>

                        {/* Content */}
                        <div className="relative text-center z-10">
                            {/* Logo */}
                            <img
                                src={asendialogo}
                                alt="Asendia Logo"
                                className="max-w-[10rem] sm:max-w-[7rem] mx-auto mb-5 opacity-90 hover:scale-105 transition-transform duration-300 ease-in-out"
                            />

                            {/* Main Heading */}
                            <h1 className="text-2xl sm:text-4xl font-bold bg-clip-text bg-gradient-to-r from-gray-50 via-indigo-200 to-gray-50 text-transparent tracking-tight">
                                Join the Asendia AI Community
                            </h1>

                            {/* Supporting Text */}
                            <p className="mt-4 text-md sm:text-lg text-gray-200 max-w-3xl mx-auto">
                                Become a part of our community to receive personalized updates, exclusive offers, and more.
                            </p>
                        </div>
                    </div>

                    {/* Display submission error message */}
                    {submissionError && (
                        <div className="mb-6 p-4 text-red-800 bg-red-100 border border-red-200 rounded">
                            {submissionError}
                        </div>
                    )}

                    {/* SECTION 1: Personal Information */}
                    <div className="border-b border-gray-200 pb-8 mb-8">
                        <h2 className="text-xl font-semibold text-gray-800">Personal Information</h2>
                        <p className="mt-1 text-sm text-gray-600">
                            Let us know a bit about you.
                        </p>
                        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-5 sm:grid-cols-6">
                            {/* First Name */}
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="firstName"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    First Name <span className="text-red-500">*</span>
                                </label>
                                <input
                                    id="firstName"
                                    name="firstName"
                                    type="text"
                                    required
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-sm text-gray-800 placeholder-gray-400 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                />
                            </div>

                            {/* Last Name */}
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="lastName"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Last Name <span className="text-red-500">*</span>
                                </label>
                                <input
                                    id="lastName"
                                    name="lastName"
                                    type="text"
                                    required
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-sm text-gray-800 placeholder-gray-400 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                />
                            </div>

                            {/* Email Address */}
                            <div className="sm:col-span-6">
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Email Address <span className="text-red-500">*</span>
                                </label>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    required
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-sm text-gray-800 placeholder-gray-400 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                />
                            </div>

                            {/* Phone Number */}
                            <div className="sm:col-span-6">
                                <label
                                    htmlFor="phoneNumber"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Phone Number <span className="text-red-500">*</span>
                                </label>
                                <PhoneInput
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    placeholder="Enter phone number"
                                    value={formData.phoneNumber}
                                    onChange={handlePhoneChange}
                                    defaultCountry="US"
                                    className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-sm text-gray-800 placeholder-gray-400 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                    required
                                />
                            </div>

                            {/* Country */}
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="country"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Country <span className="text-red-500">*</span>
                                </label>
                                <div className="relative mt-1">
                                    <select
                                        id="country"
                                        name="country"
                                        required
                                        value={formData.country}
                                        onChange={handleChange}
                                        className="block w-full appearance-none rounded-md border border-gray-300 bg-white px-3 py-2 pr-9 text-sm text-gray-800 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                    >
                                        <option value="">-- Select a country --</option>
                                        <option>United States</option>
                                        <option>Canada</option>
                                        <option>United Kingdom</option>
                                        <option>Australia</option>
                                        <option>Other</option>
                                    </select>
                                    <AiFillDownSquare
                                        className="pointer-events-none absolute top-2 right-3 h-4 w-4 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </div>
                            </div>

                            {/* Age */}
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="age"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Age <span className="text-red-500">*</span>
                                </label>
                                <input
                                    id="age"
                                    name="age"
                                    type="number"
                                    min="13"
                                    required
                                    value={formData.age}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-sm text-gray-800 placeholder-gray-400 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                    placeholder="e.g., 25"
                                />
                            </div>

                            {/* Gender */}
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="gender"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Gender
                                </label>
                                <div className="relative mt-1">
                                    <select
                                        id="gender"
                                        name="gender"
                                        value={formData.gender}
                                        onChange={handleChange}
                                        className="block w-full appearance-none rounded-md border border-gray-300 bg-white px-3 py-2 pr-9 text-sm text-gray-800 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                    >
                                        <option value="">-- Select --</option>
                                        <option>Male</option>
                                        <option>Female</option>
                                        <option>Non-binary</option>
                                        <option>Prefer not to say</option>
                                        <option>Other</option>
                                    </select>
                                    <AiFillDownSquare
                                        className="pointer-events-none absolute top-2 right-3 h-4 w-4 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </div>
                            </div>

                            {/* Interests */}
                            <div className="sm:col-span-6">
                                <label className="block text-sm font-medium text-gray-700">
                                    Interests <span className="text-red-500">*</span>
                                </label>
                                <div className="mt-2 flex flex-wrap gap-4">
                                    {['Technology', 'Health & Wellness', 'Finance', 'Education', 'Entertainment', 'Travel', 'Other'].map((interest) => (
                                        <label key={interest} className="inline-flex items-center">
                                            <input
                                                type="checkbox"
                                                name="interests"
                                                value={interest}
                                                checked={formData.interests.includes(interest)}
                                                onChange={handleCheckboxChange}
                                                className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                                            />
                                            <span className="ml-2 text-sm text-gray-700">{interest}</span>
                                        </label>
                                    ))}
                                </div>
                            </div>

                            {/* Newsletter Subscription */}
                            <div className="sm:col-span-6">
                                <label className="inline-flex items-center">
                                    <input
                                        type="checkbox"
                                        name="newsletter"
                                        checked={formData.newsletter}
                                        onChange={handleChange}
                                        className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                                    />
                                    <span className="ml-2 text-sm text-gray-700">
                                        I would like to receive the newsletter and updates.
                                    </span>
                                </label>
                            </div>

                            {/* Preferred Contact Method */}
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="preferredContact"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Preferred Contact Method <span className="text-red-500">*</span>
                                </label>
                                <div className="relative mt-1">
                                    <select
                                        id="preferredContact"
                                        name="preferredContact"
                                        required
                                        value={formData.preferredContact}
                                        onChange={handleChange}
                                        className="block w-full appearance-none rounded-md border border-gray-300 bg-white px-3 py-2 pr-9 text-sm text-gray-800 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                    >
                                        <option value="">-- Select --</option>
                                        <option>Email</option>
                                        <option>SMS</option>
                                        <option>Phone Call</option>
                                    </select>
                                    <AiFillDownSquare
                                        className="pointer-events-none absolute top-2 right-3 h-4 w-4 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </div>
                            </div>

                            {/* Feedback */}
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="feedback"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    How Did You Hear About Us?
                                </label>
                                <input
                                    id="feedback"
                                    name="feedback"
                                    type="text"
                                    value={formData.feedback}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-sm text-gray-800 placeholder-gray-400 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                    placeholder="e.g., Social Media, Friend Referral"
                                />
                            </div>
                        </div>
                    </div>

                    {/* SECTION 2: Role Identification */}
                    <div className="border-b border-gray-200 pb-8 mb-8">
                        <h2 className="text-xl font-semibold text-gray-800">Your Role</h2>
                        <p className="mt-1 text-sm text-gray-600">
                            Please select your role within the community.
                        </p>
                        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-5 sm:grid-cols-6">
                            {/* Role Options */}
                            <div className="sm:col-span-6">
                                <fieldset>
                                    <legend className="block text-sm font-medium text-gray-700 mb-2">
                                        Select Your Role <span className="text-red-500">*</span>
                                    </legend>
                                    <div className="flex flex-wrap gap-4">
                                        {['Influencer', 'Ambassador', 'Referrer', 'University Student', 'Startup', 'Other'].map((roleOption) => (
                                            <label key={roleOption} className="inline-flex items-center">
                                                <input
                                                    type="radio"
                                                    name="role"
                                                    value={roleOption}
                                                    required
                                                    checked={formData.role === roleOption}
                                                    onChange={handleChange}
                                                    className="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                                />
                                                <span className="ml-2 text-sm text-gray-700">{roleOption}</span>
                                            </label>
                                        ))}
                                    </div>
                                </fieldset>
                            </div>

                            {/* Other Role Specification */}
                            {formData.role === 'Other' && (
                                <div className="sm:col-span-6">
                                    <label
                                        htmlFor="otherRole"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Please Specify Your Role <span className="text-red-500">*</span>
                                    </label>
                                    <input
                                        id="otherRole"
                                        name="otherRole"
                                        type="text"
                                        required
                                        value={formData.otherRole}
                                        onChange={handleChange}
                                        className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-sm text-gray-800 placeholder-gray-400 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                        placeholder="e.g., Content Creator, Community Manager"
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    {/* FORM ACTIONS */}
                    <div className="flex items-center justify-end gap-x-4">
                        <button
                            type="button"
                            onClick={() => window.location.reload()} // Simple cancel action: reload the page
                            className="flex items-center justify-center gap-x-2 rounded-full border border-gray-300 px-5 py-3 text-base font-medium text-gray-700 shadow-sm transition-all duration-200 hover:border-gray-400 hover:bg-gray-50 hover:shadow-md active:shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-300"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            disabled={isSubmitting}
                            className={`flex items-center justify-center gap-x-2 rounded-full bg-indigo-600 px-5 py-3 text-base font-semibold text-white shadow-md transition-all duration-200 hover:bg-indigo-500 hover:shadow-xl active:shadow-md focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-offset-2 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                        >
                            {isSubmitting ? (
                                <>
                                    <svg
                                        className="w-6 h-6 animate-spin text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                                        ></path>
                                    </svg>
                                    Submitting...
                                </>
                            ) : (
                                <>
                                    Submit
                                </>
                            )}
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
}
