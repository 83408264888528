// OurPartnersB2C.jsx

import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import {
    FaRegHandshake,
    FaRegLightbulb,
    FaRegBuilding,
} from "react-icons/fa";
import { PiStrategyBold } from "react-icons/pi";
import {
    FiSettings,
    FiShield,
    FiZap,
    FiStar,
    FiCloud,
    FiTrendingUp,
    FiUsers,
    FiDollarSign,
    FiLayers,
} from "react-icons/fi";
import { GrTechnology } from "react-icons/gr";
import { TbWriting } from "react-icons/tb";
import avatar1 from "../../../assets/3d_avatar_1.webp";
import avatar2 from "../../../assets/3d_avatar_2.webp";
import avatar3 from "../../../assets/3d_avatar_3.webp";
import avatar4 from "../../../assets/3d_avatar_4.webp";
import avatar5 from "../../../assets/3d_avatar_5.webp";

import NewsletterSection from "../../../Components/NewsLetter/NewsLetter";
import BusinessIntroducers from "../../../assets/BusinessIntroducers.png";
import TechnologyPartners from "../../../assets/TechnologyPartners.png";
import ContentCreators from "../../../assets/ContentCreators.png";

const OurPartnersB2C = () => {
    const navigate = useNavigate();

    // Navigate to the specified route
    const handleNavigate = () => {
        navigate("/job-seekers/partners/partner-contact");
    };

    const features = [
        {
            title: "Career Growth",
            description:
                "Access personalized career paths and resources to accelerate your professional development.",
            icon: <FiTrendingUp className="w-12 h-12 text-indigo-600" />,
        },
        {
            title: "Networking Opportunities",
            description:
                "Connect with industry leaders and peers to expand your professional network.",
            icon: <FiUsers className="w-12 h-12 text-indigo-600" />,
        },
        {
            title: "Exclusive Content",
            description:
                "Gain access to premium content, webinars, and workshops tailored for your career advancement.",
            icon: <FiStar className="w-12 h-12 text-indigo-600" />,
        },
        {
            title: "Skill Development",
            description:
                "Enhance your skills with our comprehensive training programs and resources.",
            icon: <FiLayers className="w-12 h-12 text-indigo-600" />,
        },
    ];

    const contactMethods = [
        {
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                    />
                </svg>
            ),
            contact: "Support@example.com",
        },
        {
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                    />
                </svg>
            ),
            contact: "+1 (555) 000-000",
        },
        {
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                    />
                </svg>
            ),
            contact: "Mountain View, California, United States.",
        },
    ];

    const cards = [
        {
            title: "Influencers",
            description:
                "Partner with us to amplify your reach and influence through our platform, connecting with a wide audience of jobseekers.",
            button: "Join Now",
            icon: avatar1, // Replace with appropriate icon if available
        },
        {
            title: "Ambassador Program",
            description:
                "Students can join our Ambassador program to represent us on campus, gain valuable experience, and earn rewards.",
            button: "Apply Now",
            icon: avatar2, // Replace with appropriate icon if available
        },
        {
            title: "Paid Referral Program",
            description:
                "Earn rewards by referring friends and peers to our platform. The more you refer, the more you earn!",
            button: "Refer Now",
            icon: avatar3, // Replace with appropriate icon if available
        },
        {
            title: "Universities",
            description:
                "Collaborate with us to provide your students with exclusive access to job opportunities and career resources.",
            button: "Partner with Us",
            icon: avatar4, // Replace with appropriate icon if available
        },
        {
            title: "Startups",
            description:
                "Startups can leverage our platform to find top talent and build their teams efficiently.",
            button: "Get Started",
            icon: avatar5, // Replace with appropriate icon if available
        },
    ];

    // Framer Motion Variants
    const containerVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: (custom) => ({
            opacity: 1,
            y: 0,
            transition: {
                delay: custom * 0.3,
                duration: 0.6,
                ease: "easeOut",
            },
        }),
    };

    const cardVariants = {
        offscreen: {
            opacity: 0,
            y: 100,
        },
        onscreen: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 0.8,
            },
        },
    };

    return (
        <div className="bg-white">
            {/* Hero Section */}
            <motion.section
                className="py-32"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true, amount: 0.3 }}
                transition={{ duration: 1 }}
            >
                <div className="max-w-screen-xl mx-auto text-gray-600 gap-x-12 items-center justify-between overflow-hidden md:flex md:px-8">
                    <motion.div
                        className="flex-none space-y-5 px-4 sm:max-w-lg md:px-0 lg:max-w-xl"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.3 }}
                        variants={containerVariants}
                        custom={0}
                    >
                        <h1 className="text-sm text-indigo-600 font-medium">
                            Join 5,000+ Satisfied Jobseekers
                        </h1>
                        <h2 className="text-4xl text-gray-800 font-bold md:text-5xl">
                            Unlock Your{" "}
                            <span className="bg-clip-text bg-gradient-to-r from-indigo-500 via-indigo-800 to-indigo-400 text-transparent">
                                Career Potential
                            </span>{" "}
                            with Us
                        </h2>
                        <p>
                            Empower your job search with personalized tools and connect with top
                            employers seamlessly.
                        </p>
                        <div className="items-center gap-x-3 space-y-3 sm:flex sm:space-y-0">
                            <a
                                onClick={handleNavigate}
                                className="cursor-pointer block py-3 px-5 text-center text-white font-medium bg-indigo-600 duration-150 hover:bg-indigo-500 active:bg-indigo-700 rounded-full shadow-lg hover:shadow-none"
                            >
                                Join Now
                            </a>
                            <a
                                href="https://chat.whatsapp.com/Io18gwV6t2R"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center justify-center gap-x-2 py-3 px-5 text-gray-700 hover:text-gray-500 font-medium duration-150 active:bg-gray-100 border rounded-full md:inline-flex"
                            >
                                Join our Community
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="w-5 h-5"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </a>
                        </div>
                    </motion.div>
                    <motion.div
                        className="relative flex-none mt-14 md:mt-0 md:max-w-xl"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        variants={containerVariants}
                        custom={1}
                    >
                        {/* Image */}
                        <motion.img
                            src="https://images.unsplash.com/photo-1568992687947-868a62a9f521?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            className="md:rounded-tl-[108px] relative z-10 h-[360px]"
                            alt="Hero Banner"
                            initial={{ scale: 0.9, opacity: 0 }}
                            whileInView={{ scale: 1, opacity: 1 }}
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ duration: 0.8, ease: "easeOut" }}
                        />
                        {/* Decorative Lines */}
                        <div className="absolute inset-0 pointer-events-none">
                            {/* Top Left Line */}
                            <motion.div
                                style={{
                                    borderTopLeftRadius: "20px",
                                }}
                                className="absolute top-[-10px] left-[-15px] w-20 h-20 border-t-4 mt-8 border-l-4 border-indigo-500"
                                initial={{ opacity: 0, x: -50, y: -50 }}
                                whileInView={{ opacity: 1, x: 0, y: 0 }}
                                viewport={{ once: true, amount: 0.3 }}
                                transition={{ duration: 1, ease: "easeOut" }}
                            ></motion.div>
                            {/* Bottom Right Line */}
                            <motion.div
                                className="absolute bottom-[-20px] right-[-20px] w-20 h-20 border-b-4 border-r-4 border-indigo-500"
                                initial={{ opacity: 0, x: 50, y: 50 }}
                                whileInView={{ opacity: 1, x: 0, y: 0 }}
                                viewport={{ once: true, amount: 0.3 }}
                                transition={{ duration: 1, ease: "easeOut" }}
                            ></motion.div>
                            {/* Animated Diagonal Line */}
                            {/* You can add more decorative animations here if desired */}
                        </div>
                    </motion.div>
                </div>
            </motion.section>

            {/* Partnership Opportunities Section */}
            <motion.section
                className="bg-gray-50 py-12"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, amount: 0.3 }}
                transition={{ duration: 0.8, ease: "easeOut" }}
            >
                <div className="max-w-7xl mx-auto px-6 lg:px-8">
                    {/* Header */}
                    <motion.div
                        className="text-center mb-12"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.3 }}
                        variants={containerVariants}
                        custom={0}
                    >
                        <h2 className="text-3xl sm:text-4xl font-bold text-gray-900  mt-12 mb-4 text-center">
                            Join the{" "}
                            <span className="bg-clip-text bg-gradient-to-r from-indigo-500 via-indigo-800 to-indigo-400 text-transparent">
                                Asendia AI
                            </span>{" "}
                            Community
                        </h2>
                        <p className="mt-4 text-base text-gray-600 max-w-2xl mx-auto">
                            Explore a variety of programs designed to support and empower your career
                            journey.
                        </p>
                    </motion.div>

                    {/* Partnership Cards */}
                    <motion.div
                        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.3 }}
                        variants={{
                            visible: {
                                transition: {
                                    staggerChildren: 0.2,
                                },
                            },
                            hidden: {},
                        }}
                    >
                        {cards.map(({ title, description, button, icon }, index) => (
                            <motion.div
                                key={index}
                                className="bg-white p-8 rounded-3xl shadow-md hover:shadow-xl transition-shadow duration-300 flex flex-col items-center text-center"
                                variants={cardVariants}
                            >
                                <img
                                    className="h-[150px] w-[150px] mb-10 rounded-full"
                                    src={icon}
                                    alt={`${title} Icon`}
                                />

                                <h4 className="text-xl font-semibold text-gray-800 mb-2 mt-2">
                                    {title}
                                </h4>
                                <p className="text-gray-600 mb-4">{description}</p>
                                <button
                                    onClick={handleNavigate}
                                    className="mt-auto px-5 py-2 bg-white text-indigo-700 rounded-full hover:bg-indigo-400 hover:text-white transition flex items-center gap-2"
                                >
                                    {button}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M9 5l7 7-7 7"
                                        />
                                    </svg>
                                </button>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </motion.section>

            {/* Contact Us Section */}
            <motion.section
                className="py-24"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, amount: 0.3 }}
                transition={{ duration: 0.8, ease: "easeOut" }}
            >
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <motion.div
                        className="mb-10 lg:mb-16 flex justify-center items-center flex-col gap-x-0 gap-y-6 lg:gap-y-0 lg:flex-row lg:justify-between max-md:max-w-lg max-md:mx-auto"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.3 }}
                        variants={containerVariants}
                        custom={0}
                    >
                        <div className="relative w-full text-center lg:text-left lg:w-2/3">
                            <h2 className="text-block text-3xl font-bold text-gray-900 leading-[2rem] lg:mb-6 mx-auto max-w-max lg:max-w-[35rem] lg:mx-0">
                                Joining{" "}
                                <span className="bg-clip-text bg-gradient-to-r from-indigo-500 via-indigo-800 to-indigo-400 text-transparent">
                                    Asendia AI
                                </span>{" "}
                                means partnering with a team invested in your career success.
                            </h2>
                        </div>
                        <div className="relative w-full text-center lg:text-left lg:w-2/4">
                            <p className="text-lg font-normal text-gray-500 mb-5">
                                We provide you with all the tools and resources you need to advance your
                                career and achieve your professional goals.
                            </p>
                            <a
                                onClick={handleNavigate}
                                className="flex flex-row items-center justify-center gap-2 text-base font-semibold text-indigo-600 lg:justify-start hover:text-indigo-700"
                            >
                                Get Started
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M7.5 15L11.0858 11.4142C11.7525 10.7475 12.0858 10.4142 12.0858 10C12.0858 9.58579 11.7525 9.25245 11.0858 8.58579L7.5 5"
                                        stroke="#4F46E5"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    ></path>
                                </svg>
                            </a>
                        </div>
                    </motion.div>
                    <motion.div
                        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-md:max-w-md mx-auto"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.3 }}
                        variants={{
                            visible: {
                                transition: {
                                    staggerChildren: 0.2,
                                },
                            },
                            hidden: {},
                        }}
                    >
                        {features.map((feature, index) => (
                            <motion.div
                                key={index}
                                className="border bg-white rounded-2xl overflow-hidden shadow-md hover:shadow-lg transition-all"
                                variants={cardVariants}
                            >
                                <div className="p-8 text-left flex flex-col gap-6">
                                    {feature.icon}
                                    <div>
                                        <h3 className="text-gray-800 text-xl font-semibold mb-3">
                                            {feature.title}
                                        </h3>
                                        <p className="text-gray-500 text-sm leading-relaxed">
                                            {feature.description}
                                        </p>
                                    </div>
                                </div>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </motion.section>

            {/* Benefits Section */}
            <motion.section
                className="py-16 bg-white"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, amount: 0.3 }}
                transition={{ duration: 0.8, ease: "easeOut" }}
            >
                <div className="container mx-auto text-center">
                    <motion.h2
                        className="text-3xl sm:text-4xl font-bold text-gray-900 mt-6 mb-4 text-center"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.3 }}
                        variants={containerVariants}
                        custom={0}
                    >
                        How Asendia AI Enhances Your Career
                    </motion.h2>
                    <motion.p
                        className="text-lg text-gray-600 mb-12"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.3 }}
                        variants={containerVariants}
                        custom={1}
                    >
                        Differentiate yourself by leveraging our resources and partnerships to
                        maximize your career potential.
                    </motion.p>
                    <motion.div
                        className="flex justify-center items-center space-x-4"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.3 }}
                        variants={{
                            visible: {
                                transition: {
                                    staggerChildren: 0.2,
                                },
                            },
                            hidden: {},
                        }}
                    >
                        <motion.div
                            className="bg-gradient-to-tr from-indigo-200 to-indigo-100 rounded-full p-6"
                            variants={cardVariants}
                        >
                            <img
                                src={avatar3}
                                alt="Benefit 3"
                                className="h-20 w-20 rounded-full object-cover"
                            />
                        </motion.div>
                        <motion.div
                            className="bg-gradient-to-tr from-indigo-200 to-indigo-100 rounded-full p-6"
                            variants={cardVariants}
                        >
                            <img
                                src={avatar1}
                                alt="Benefit 1"
                                className="h-20 w-20 rounded-full object-cover"
                            />
                        </motion.div>
                        <motion.div
                            className="bg-gradient-to-tr from-indigo-200 to-indigo-100 rounded-full p-6"
                            variants={cardVariants}
                        >
                            <img
                                src={avatar2}
                                alt="Benefit 2"
                                className="h-20 w-20 rounded-full object-cover"
                            />
                        </motion.div>
                    </motion.div>
                    {/* Optional Learn More Button */}
                    {/* <button onClick={handleNavigate} className="mt-8 bg-indigo-600 text-white py-3 px-8 rounded-full font-medium hover:bg-indigo-700 transition">
              Learn More
          </button> */}
                </div>
            </motion.section>

            {/* Newsletter Section */}
            <motion.div
                className="max-w-7xl mx-auto"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true, amount: 0.3 }}
                transition={{ duration: 1 }}
            >
                <NewsletterSection />
            </motion.div>
        </div>
    );
};

export default OurPartnersB2C;
