// src/components/AnnouncementBanner.js
import React from "react";
import { motion } from "framer-motion";

const AnnouncementBanner = () => {
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.7, ease: "easeOut" },
    },
  };

  return (
    <motion.div
      className="relative overflow-hidden mb-8"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.2 }}
      variants={containerVariants}
    >
      <motion.div
        className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-2 !mt-10"
        variants={containerVariants}
      >
        <motion.div className="flex justify-center" variants={itemVariants}>
          <a
            className="inline-flex items-center gap-x-2 bg-white border border-gray-200 text-sm text-gray-800 p-1 ps-3 rounded-full transition hover:border-gray-300 focus:outline-none focus:border-gray-300"
            href={`${window.location.origin}/early-access`}
          >
            <span className="bg-clip-text bg-gradient-to-r from-indigo-500 via-indigo-800 to-indigo-400 text-transparent">
              Asendia AI{" "}
            </span>
            - Join to waitlist{" "}
            <span className="py-1.5 px-2.5 inline-flex justify-center items-center gap-x-2 rounded-full bg-gray-200 font-semibold text-sm text-gray-600">
              <svg
                className="shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="m9 18 6-6-6-6" />
              </svg>
            </span>
          </a>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default AnnouncementBanner;
