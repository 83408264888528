import React, { useState } from 'react';
import { motion } from 'framer-motion';
import {
    FaChartLine,
    FaBriefcase,
    FaBuilding,
} from 'react-icons/fa';
import { MdRocketLaunch } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { PricingCard } from './PricingCard';
import { ContactSalesCard } from './ContactSalesCard';



export const PricingCardSection = () => {
    const [isAnnual, setIsAnnual] = useState(false);
    const navigate = useNavigate();

    const pricingCards = [
        {
            title: 'Starter',
            price: {
                amount: isAnnual ? '$127' : '$159',
                period: isAnnual ? '/month' : '/month',
            },
            description:
                '1 job posting with 50 interviews included and advanced analysis of interviews.',
            buttonText: 'Get Started',
            buttonLink: `${window.location.origin}/early-access`,
            features: [
                { icon: 'check', text: '1 job posting' },
                { icon: 'check', text: '1 seats included' },
                { icon: 'check', text: '50 interviews included' },
                {
                    icon: 'check',
                    text: 'Advanced analysis of interviews (summary, scoring, and more)',
                },
                {
                    icon: 'info',
                    text: 'Each new job opening costs $10, and each new interview costs $3.'
                }
            ],
            icon: <MdRocketLaunch size={32} className="text-indigo-600" />,
        },
        {
            title: 'Growth',
            popular: true, // Flag to indicate this is the popular plan
            price: {
                amount: isAnnual ? '$319' : '$399',
                period: isAnnual ? '/month' : '/month',
            },
            description:
                '3 job postings with 250 interviews included and access to our talent hub.',
            buttonText: 'Get Started',
            buttonLink: `${window.location.origin}/early-access`,
            features: [
                { icon: 'check', text: '3 job postings' },
                { icon: 'check', text: '4 seats included' },
                { icon: 'check', text: '250 interviews included' },
                { icon: 'check', text: 'Full access to our talent hub' },
                {
                    icon: 'check',
                    text: 'Change interview voice and language',
                },
                { icon: 'check', text: 'Customer support' },
                {
                    icon: 'info',
                    text: 'Each new job opening costs $10, and each new interview costs $3.'
                }
            ],
            icon: <FaChartLine size={32} className="text-indigo-600" />,
        },
        {
            title: 'Business',
            price: {
                amount: isAnnual ? '$639' : '$799',
                period: isAnnual ? '/month' : '/month',
            },
            description:
                '10 job postings, 8 seats, and 750 interviews included with ATS integrations.',
            buttonText: 'Get Started',
            buttonLink: `${window.location.origin}/early-access`,
            features: [
                { icon: 'check', text: '10 job postings' },
                { icon: 'check', text: '8 seats included' },
                { icon: 'check', text: '750 interviews included' },
                { icon: 'check', text: '20+ ATS integrations' },
                {
                    icon: 'check',
                    text: 'Early access to new features and discounts',
                },
                {
                    icon: 'info',
                    text: 'Each new job opening costs $10, and each new interview costs $3.'
                }
            ],
            icon: <FaBriefcase size={32} className="text-indigo-600" />,
        },
        {
            title: 'Enterprise',
            price: {
                amount: 'Contact Sales',
                period: '',
            },
            description:
                'Unlimited job postings and interviews with custom solutions for large teams.',
            buttonText: 'Contact Us',
            buttonLink: 'javascript:;',
            buttonOnClick: () => {
                navigate('/talk-to-founders');
            },
            features: [
                { icon: 'check', text: 'Unlimited job postings' },
                { icon: 'check', text: 'Unlimited seats' },
                { icon: 'check', text: 'Unlimited interviews included' },
                { icon: 'check', text: 'Dedicated recruiting support' },
                { icon: 'check', text: 'Custom voices and dialects' },
                {
                    icon: 'check',
                    text: 'Create interviews via Asendia AI API',
                },
                {
                    icon: 'check',
                    text: 'Early access to new features and discounts',
                },
                { icon: 'check', text: '24/7 customer support' },
                { icon: 'check', text: 'Integration of AI assistants' },
            ],
            icon: <FaBuilding size={32} className="text-indigo-300" />,
        },
    ];

    const planCards = pricingCards.slice(0, 3);
    const contactSalesCard = pricingCards.find(card => card.buttonText === "Contact Us");


    return (
        <div className="py-12  rounded-3xl">
            {/* Section Title */}
            <motion.div
                className="max-w-2xl mx-auto text-center mb-10 lg:mb-10"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
            >
                <section className="flex flex-col items-center text-center space-y-6">
                    <motion.h2
                        className="text-3xl sm:text-5xl font-bold text-slate-900 "
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        Flexible{' '}
                        <span className="bg-clip-text bg-gradient-to-r from-indigo-500 via-indigo-800 to-indigo-400 text-transparent">
                            Pricing
                        </span>{' '}
                        Plans
                    </motion.h2>
                    <motion.p
                        className="text-gray-600  max-w-2xl mx-auto text-base sm:text-lg leading-relaxed"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                    >
                        Choose a plan that fits your needs. Upgrade or downgrade at any time.
                    </motion.p>
                </section>
            </motion.div>
            {/* End Title */}

            {/* Toggle Switch for Monthly/Annual */}
            <motion.section
                className="flex justify-center items-center py-12 mb-6"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
            >
                <div className="flex items-center space-x-4">
                    <motion.label
                        className="min-w-14 text-sm text-gray-500 "
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        Monthly
                    </motion.label>

                    <label
                        htmlFor="pricing-toggle"
                        className="relative inline-flex items-center cursor-pointer"
                    >
                        <input
                            type="checkbox"
                            id="pricing-toggle"
                            className="sr-only peer"
                            checked={isAnnual}
                            onChange={() => setIsAnnual(!isAnnual)}
                        />
                        <motion.div
                            className="w-12 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-indigo-500 rounded-full peer transition-colors duration-300 ease-in-out peer-checked:bg-indigo-600"
                            initial={{ backgroundColor: '#E5E7EB' }} // Tailwind's bg-gray-200
                            animate={{
                                backgroundColor: isAnnual
                                    ? '#4F46E5' // Tailwind's bg-indigo-600
                                    : '#E5E7EB',
                            }}
                            transition={{ duration: 0.3 }}
                        ></motion.div>
                        <motion.div
                            className="absolute left-0.5 top-0.5 w-5 h-5 bg-white rounded-full shadow-md transition-transform duration-300 ease-in-out peer-checked:translate-x-6"
                            initial={{ x: 0 }}
                            animate={{ x: isAnnual ? 24 : 0 }}
                            transition={{
                                type: 'spring',
                                stiffness: 700,
                                damping: 30,
                            }}
                        ></motion.div>
                    </label>

                    <motion.label
                        className="relative min-w-14 text-sm text-gray-500 "
                        initial={{ opacity: 0, x: 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                    >
                        Annual
                        <span className="absolute -top-10 start-auto -end-28">
                            <span className="flex items-center">
                                <svg
                                    className="w-14 h-8 -me-6"
                                    width="45"
                                    height="25"
                                    viewBox="0 0 45 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M43.2951 3.47877C43.8357 3.59191 44.3656 3.24541 44.4788 2.70484C44.5919 2.16427 44.2454 1.63433 43.7049 1.52119L43.2951 3.47877ZM4.63031 24.4936C4.90293 24.9739 5.51329 25.1423 5.99361 24.8697L13.8208 20.4272C14.3011 20.1546 14.4695 19.5443 14.1969 19.0639C13.9242 18.5836 13.3139 18.4152 12.8336 18.6879L5.87608 22.6367L1.92723 15.6792C1.65462 15.1989 1.04426 15.0305 0.563943 15.3031C0.0836291 15.5757 -0.0847477 16.1861 0.187863 16.6664L4.63031 24.4936ZM43.7049 1.52119C32.7389 -0.77401 23.9595 0.99522 17.3905 5.28788C10.8356 9.57127 6.58742 16.2977 4.53601 23.7341L6.46399 24.2659C8.41258 17.2023 12.4144 10.9287 18.4845 6.96211C24.5405 3.00476 32.7611 1.27399 43.2951 3.47877L43.7049 1.52119Z"
                                        fill="currentColor"
                                        className="fill-gray-300"
                                    ></path>
                                </svg>
                                <span className="mt-3 inline-block whitespace-nowrap text-[11px] leading-5 font-semibold tracking-wide uppercase bg-indigo-600 text-white rounded-full py-1 px-2.5">
                                    Save up to 20%
                                </span>
                            </span>
                        </span>
                    </motion.label>
                </div>
            </motion.section>
            {/* End Toggle Switch */}

            {/* Pricing Cards */}
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-2 py-8">
                {/* Grid for the first three pricing plans */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {planCards.map((card, index) => (
                        <PricingCard key={index} {...card} />
                    ))}
                </div>

                {/* Full-width Contact Sales card */}
                {contactSalesCard && (
                    <div className="mt-8">
                        <ContactSalesCard {...contactSalesCard} />
                    </div>
                )}
            </div>
            {/* End Pricing Cards */}
        </div>
    );
}