import React from "react";
import { FaWhatsapp, FaTwitter, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai';
import { FaMapMarkerAlt } from 'react-icons/fa'
import { FaXTwitter } from "react-icons/fa6";
import { LuBuilding } from "react-icons/lu";

const Footer = () => {
  return (
    <footer className="w-full bg-white text-gray-800 py-10 border-t border-gray-100">

      <div className="py-10 container mx-auto max-w-7xl px-4 sm:px-6 grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Logo and Description */}
        <div className="md:w-[70%]">
          <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.791992" y="0.689453" width="60" height="60" rx="7.2" fill="white" />
            <path d="M40.7548 29.3135C40.6024 30.1327 40.2575 30.904 39.7485 31.5637C39.0592 32.4598 38.088 33.1031 36.9691 33.2057C35.0367 33.3811 33.3326 31.9933 31.9977 30.5818C29.5262 27.9686 25.5719 25.2206 22.2303 28.3087C20.7207 29.7033 20.2313 31.7467 19.7733 33.6699C19.4302 35.109 19.3061 37.0666 17.9283 37.9987C17.6336 38.1952 17.3108 38.346 16.971 38.446C14.4902 39.1927 12.1459 37.6035 11.7706 35.0401C11.5408 33.4401 11.6534 31.6418 11.8824 30.2471C12.8015 24.6423 14.2766 18.3215 19.6707 15.1507C22.117 13.7131 25.0779 13.3125 27.8573 13.7652C33.2752 14.6498 37.5021 18.8201 39.7201 23.6681C40.5289 25.4366 41.1056 27.4011 40.7548 29.3135Z" fill="url(#paint0_linear_170_461)" />
            <path d="M21.3587 32.1891C21.5111 31.3699 21.856 30.5986 22.3651 29.9389C23.0544 29.0428 24.0255 28.3994 25.1445 28.2968C27.0768 28.1214 28.7809 29.5092 30.1159 30.9208C32.5874 33.534 36.5417 36.282 39.8832 33.1939C41.3928 31.7992 41.8822 29.7558 42.3402 27.8327C42.6833 26.3936 42.8074 24.436 44.1852 23.5039C44.48 23.3073 44.8027 23.1565 45.1426 23.0566C47.6233 22.3099 49.9715 23.8991 50.343 26.4625C50.5727 28.0625 50.4601 29.8608 50.2311 31.2554C49.3121 36.8602 47.837 43.1811 42.4428 46.3519C39.9966 47.7895 37.0357 48.19 34.2563 47.7374C28.8384 46.8528 24.6114 42.6825 22.3934 37.8344C21.5846 36.066 21.0079 34.1015 21.3587 32.1891Z" fill="url(#paint1_linear_170_461)" />
            <defs>
              <linearGradient id="paint0_linear_170_461" x1="32.2152" y1="49.119" x2="24.2055" y2="11.7682" gradientUnits="userSpaceOnUse">
                <stop offset="0.5058" stop-color="#180031" />
                <stop offset="0.785" stop-color="#3A225C" />
              </linearGradient>
              <linearGradient id="paint1_linear_170_461" x1="41.8246" y1="58.4022" x2="33.8149" y2="21.0514" gradientUnits="userSpaceOnUse">
                <stop offset="0.5058" stop-color="#180031" />
                <stop offset="0.785" stop-color="#3A225C" />
              </linearGradient>
            </defs>
          </svg>
          <h1 className="text-xl font-semibold text-gray-900">Asendia AI</h1>
          <p className="text-sm text-gray-500 mt-4 leading-relaxed">
            Join our vibrant community and be part of the new AI society
          </p>
          <div className="flex space-x-4 mt-6">
            <a
              href="https://chat.whatsapp.com/Io18gNUCKqcDWQtjwV6t2R"
              aria-label="Whatsapp"
              className="text-gray-500 hover:text-gray-900 transition duration-200"
            >
              <FaWhatsapp className="w-5 h-5" />
            </a>
            <a
              href="https://x.com/asendia_ai"
              aria-label="Twitter"
              className="text-gray-500 hover:text-gray-900 transition duration-200"
            >
              <FaXTwitter className="w-5 h-5" />
            </a>
            <a
              href="https://www.linkedin.com/company/asendia-ai/?viewAsMember=true"
              aria-label="LinkedIn"
              className="text-gray-500 hover:text-gray-900 transition duration-200"
            >
              <FaLinkedin className="w-5 h-5" />
            </a>
          </div>
        </div>

        {/* Navigation and Contact Information */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          {/* Navigation Links */}
          <div>
            <h2 className="text-sm font-semibold text-gray-900 uppercase tracking-wider">
              Navigation
            </h2>
            <ul className="mt-4 space-y-2">
              <li>
                <Link
                  to="/companies"
                  className="text-sm text-gray-500 hover:text-gray-900 transition duration-200"
                >
                  For Recruiters
                </Link>
              </li>
              <li>
                <Link
                  to="/job-seekers"
                  className="text-sm text-gray-500 hover:text-gray-900 transition duration-200"
                >
                  For Jobseekers
                </Link>
              </li>
              <li>
                <Link
                  to="/aboutus"
                  className="text-sm text-gray-500 hover:text-gray-900 transition duration-200"
                >
                  About Us
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact Information */}
          <div>
            <h2 className="text-sm font-semibold text-gray-900 uppercase tracking-wider">
              Contact
            </h2>
            <ul className="mt-4 space-y-4">
              <li className="flex items-center text-sm text-gray-500">
                <LuBuilding  className="text-gray-400 w-5 h-5 mr-3" />
                Asendia Inc
              </li>
              <li className="flex items-center text-sm text-gray-500">
                <AiOutlineMail className="text-gray-400 w-5 h-5 mr-3" />
                <a href="mailto:connect@asendia.ai" className="text-gray-500 hover:text-gray-700">
                  connect@asendia.ai
                </a>
              </li>

              <li className="flex items-center text-sm text-gray-500">
                <FaMapMarkerAlt className="text-gray-400 w-5 h-5 mr-3" />
                251 Little Falls Drive, Wilmington, Delaware 19808
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Footer Bottom Row */}
      <div className="container mx-auto max-w-7xl px-4 sm:px-6 mt-8 flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
        <p className="text-sm text-gray-500 text-center sm:text-left">
          2025  © Asendia AI, Inc. All rights reserved.
        </p>
        <div className="flex space-x-4">
          <Link
            to="/terms-and-conditions"
            className="text-sm text-gray-500 hover:text-gray-900 transition duration-200"
          >
            Terms
          </Link>
          <Link
            to="/privacy-policy"
            className="text-sm text-gray-500 hover:text-gray-900 transition duration-200"
          >
            Privacy Policy
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
