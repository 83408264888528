// src/components/TitleSection.js 
import React from "react";
import { motion } from "framer-motion";

const TitleSection = () => {
  // Variants for staggered animations
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.7, ease: "easeOut" },
    },
  };

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.2 }}
      variants={containerVariants}
    >
      {/* Title */}
      <motion.div
        className="mt-5 max-w-2xl text-center mx-auto !-mt-4"
        variants={itemVariants}
      >
        <h1 className="block font-bold text-gray-800 text-4xl md:text-5xl lg:text-6xl">
          Let's Build{" "}
          <span className="bg-clip-text bg-gradient-to-r from-indigo-500 via-indigo-800 to-indigo-400 text-transparent">
            Together
          </span>
        </h1>
      </motion.div>
      {/* End Title */}

      <motion.div
        className="mt-5 max-w-3xl text-center mx-auto"
        variants={itemVariants}
      >
        <p className="text-lg text-gray-600">
          To revolutionize HR through cutting-edge AI assistants, transforming
          the way businesses hire, manage, and develop talent.
        </p>
      </motion.div>
    </motion.div>
  );
};

export default TitleSection;
