import React, { useState } from 'react';
import { FiCheck, FiInfo, FiX } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

export const ContactSalesCard = ({
    title,
    description,
    features,
    buttonText,
    buttonLink,
    icon,
}) => {
    const navigate = useNavigate();
    return (
        <div className="relative flex flex-col lg:flex-row items-center justify-between bg-gradient-to-r from-gray-800 via-gray-900 to-gray-800 text-white border border-gray-700 rounded-2xl shadow-2xl p-10 lg:p-12 lg:!py-14 gap-8 hover:shadow-2xl transition-shadow duration-300">
            {/* Decorative Element */}
            <div className="absolute -top-6 -right-6 w-32 h-32 bg-gradient-to-r from-indigo-500 via-purple-500 to-indigo-500 opacity-30 blur-2xl rounded-full pointer-events-none"></div>
            <div className="absolute -bottom-8 -left-8 w-40 h-40 bg-gradient-to-br from-purple-500 to-indigo-500 opacity-20 blur-3xl rounded-full pointer-events-none"></div>

            {/* Left Section: Title */}
            <div className="flex flex-col lg:w-1/3">
                <div className="mb-4">{icon}</div>
                <h3 className="text-4xl font-bold mb-4">{title}</h3>
                <p className="text-md text-gray-400">{description}</p>
            </div>

            {/* Middle Section: Features */}
            <div className="flex-grow lg:w-1/2">
                <ul className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    {features.map((feature, index) => (
                        <li
                            key={index}
                            className="flex items-center text-sm text-gray-300"
                        >
                            <div
                                className={`flex items-center justify-center w-6 h-6 rounded-full ${feature.icon === "check"
                                    ? "bg-indigo-200 text-white"
                                    : "bg-red-600 text-white"
                                    } mr-3`}
                            >
                                {feature.icon === "check" ? (
                                    <FiCheck className="text-indigo-600 w-4 h-4" />
                                ) : feature.icon === "info" ? (
                                    <FiInfo className="text-indigo-600 w-4 h-4" />
                                ) : (
                                    <FiX className="w-4 h-4" />
                                )}

                            </div>
                            {feature.text}
                        </li>
                    ))}
                </ul>
            </div>

            {/* Right Section: Button */}
            <div className="lg:w-1/5 text-center">
                <a
                    onClick={() => navigate('/talk-to-founders')}
                    className="inline-block py-4 px-10 text-md font-semibold bg-gradient-to-r from-indigo-600 to-indigo-800 text-white rounded-lg shadow-lg hover:from-indigo-500 hover:to-indigo-500 transition duration-300"
                >
                    {buttonText}
                </a>
            </div>
        </div>
    );
};