// src/components/Blog.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion'; // Import Framer Motion
import badisPicture from "../../../assets/badis_picture.jpg";

// Define animation variants outside the component for better performance
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2, // Staggers the animation of child components
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 50,
      damping: 20,
    },
  },
};

const Blog = () => {
  const blogPosts = [
    {
      id: 'ai-agents-revolutionizing-recruitment',
      title: 'Let’s Face It: AI Agents Will Be the Headline of 2025 And They’re Revolutionizing Recruitment',
      description:
        'From Chaos to Clarity: How AI Agents Are Helping Recruiters Save Time, Cut Costs, and Find the Best Talent Faster',
      imgSrc:
        'https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2Ff48f6697-17b0-42d8-815c-eeba2dcfc249_1128x686.png',
      authorImg: badisPicture,
      author: 'Badis Zormati',
      date: 'Jan 08, 2025',
      slug: 'ai-agents-revolutionizing-recruitment',
    },
    {
      id: "launch-asendia-ai-integrated-recruitment-ecosystem",
      title: "We're Launching Asendia AI: The Fully Integrated AI Ecosystem for Recruiters and Job Seekers! 🚀",
      description:
        'Our cutting-edge Generative AI platform automates HR tasks end-to-end, providing businesses and recruitment firms with an agentic-based framework that streamlines processes.',
      imgSrc:
        "https://t9012173995.p.clickup-attachments.com/t9012173995/b073532b-f310-4507-97fd-622183cc0d80/We're%20Launching%20Asendia%20AI_%20The%20Fully%20Integrated%20AI%20Ecosystem%20for%20Recruiters%20and%20Job%20Seekers!%20%F0%9F%9A%80%20-%20visual%20selection.png",
      authorImg: badisPicture,
      author: 'Badis Zormati',
      date: 'Jan 21, 2025',
      slug: 'launch-asendia-ai-integrated-recruitment-ecosystem',
    },
    // Add more blog posts as needed
  ];

  return (
    <>
      <Helmet>
        <title>Our Blogs ─ Asendia AI</title>
        <meta
          name="description"
          content="Discover insightful blogs on how AI agents are revolutionizing recruitment, helping businesses save time, cut costs, and find the best talent faster."
        />
        <meta property="og:title" content="Our Blogs ─ Asendia AI" />
        <meta
          property="og:description"
          content="Explore how AI agents are transforming the recruitment landscape, offering smarter and more efficient hiring solutions."
        />
        <meta property="og:image" content="https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2Ff48f6697-17b0-42d8-815c-eeba2dcfc249_1128x686.png" />
        <meta property="og:url" content="https://asendia.ai.com/blog" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Our Blogs ─ Asendia AI" />
        <meta name="twitter:description" content="Discover insightful blogs on how AI agents are revolutionizing recruitment, helping businesses save time, cut costs, and find the best talent faster." />
        <meta name="twitter:image" content="https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2Ff48f6697-17b0-42d8-815c-eeba2dcfc249_1128x686.png" />
      </Helmet>
      
      <motion.div
        className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto"
        initial="hidden"
        animate="visible"
        variants={containerVariants} // Apply container variants
      >
        {/* Title */}
        <div className='w-full flex mb-16'>
          <section className="max-w-[45rem] mx-auto flex flex-col items-center text-center space-y-6">
            <motion.h2
              className="-mb-1 bg-clip-text bg-gradient-to-r from-indigo-500 via-indigo-800 to-indigo-400 text-transparent text-lg sm:text-lg font-normal"
              variants={itemVariants} // Apply item variants
              whileInView="visible"
              viewport={{ once: true, amount: 0.2 }}
            >
              Our Blogs
            </motion.h2>
            <motion.h1
              className="text-3xl sm:text-4xl font-bold text-slate-900"
              variants={itemVariants}
              whileInView="visible"
              viewport={{ once: true, amount: 0.2 }}
            >
              Discover insightful{" "}
              <span className="bg-clip-text bg-gradient-to-r from-indigo-500 via-indigo-800 to-indigo-400 text-transparent">
                Blogs
              </span>{" "}
              to help you achieve more
            </motion.h1>
            {/* Optional Description */}
            {/* <p className="text-gray-600 max-w-2xl mx-auto text-base sm:text-lg leading-relaxed">
              At Asendia how game-changing companies are making the most of every engagement with Preline.
            </p> */}
          </section>
        </div>
        {/* End Title */}

        {/* Grid */}
        <motion.div
          className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6"
          variants={containerVariants} // Apply container variants for stagger
        >
          {/* Card */}
          {blogPosts.map((post) => (
            <motion.div
              key={post.id}
              variants={itemVariants} // Apply item variants to each card
              whileHover={{ scale: 1, boxShadow: '0px 10px 20px rgba(106, 106, 106, 0.14)' }} // Hover effect
              whileTap={{ scale: 0.95 }}
              initial="hidden"
              className='rounded-3xl'
              whileInView="visible"
              viewport={{ once: true, amount: 0.2 }}
            >
              <Link
                to={`/blogs/${post.id}`}
                className="group flex shadow-lg flex-col h-full border border-gray-200 hover:border-transparent hover:shadow-lg focus:outline-none focus:border-transparent focus:shadow-lg transition duration-300 rounded-3xl p-5"
              >
                <div className="aspect-w-16 aspect-h-11 min-h-[230px] flex justify-center items-center">
                  <img
                    className="w-full object-cover rounded-xl"
                    src={post.imgSrc}
                    alt={post.title}
                  />
                </div>
                <div className="my-6">
                  <h3 className="text-xl font-semibold text-gray-800">
                    {post.title}
                  </h3>
                  <p className="mt-5 text-gray-600">
                    {post.description}
                  </p>
                </div>
                <div className="mt-auto flex items-center gap-x-3">
                  <img
                    className="w-8 h-8 rounded-full"
                    src={post.authorImg}
                    alt={post.author}
                  />
                  <div>
                    <h5 className="text-sm text-gray-800">
                      By {post.author}
                    </h5>
                    <p className="text-xs text-gray-500 ">{post.date}</p>
                  </div>
                </div>
              </Link>
            </motion.div>
          ))}
        </motion.div>
        {/* End Grid */}

        {/* Read More Button */}
        {/* <div className="mt-12 text-center">
          <Link
            to="/blogs"
            className="py-3 px-4 inline-flex items-center gap-x-1 text-sm font-medium rounded-full border border-gray-200 bg-white text-blue-600 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-blue-500 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
          >
            Read more
            <svg
              className="shrink-0 w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="m9 18 6-6-6-6" />
            </svg>
          </Link>
        </div> */}
        {/* End Read More Button */}
      </motion.div>
    </>
  );
};

export default Blog;
