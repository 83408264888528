import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FiCheck, FiInfo, FiX } from 'react-icons/fi';
import { AiOutlineStar } from 'react-icons/ai';
import { MdPhone } from "react-icons/md";
import { useNavigate } from 'react-router-dom';


export const PricingCard = ({
    title,
    price,
    description,
    buttonText,
    buttonLink,
    buttonOnClick,
    features,
    icon,
    popular = false,
}) => {
    const navigate = useNavigate();
    return (
        <motion.div
            className="flex flex-col"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <div
                className={`h-full relative flex flex-col flex-grow bg-white border rounded-2xl shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:scale-105 ${popular
                    ? 'border-2 !bg-[#c7d2fe2e] border-indigo-700 shadow-lg'
                    : 'border border-gray-200'
                    }`}
            >
                {/* Popular Badge */}
                {popular && (
                    <div className="absolute top-0 right-0 mt-4 mr-4">
                        <span className="flex items-center px-3 py-1 bg-indigo-600 text-white text-xs font-semibold rounded-full shadow">
                            <AiOutlineStar className="mr-1" />
                            Popular
                        </span>
                    </div>
                )}

                <div className="flex flex-col items-center p-6">
                    {/* Icon */}
                    <div className="mb-4">{icon}</div>

                    {/* Title */}
                    <h3 className="text-xl font-semibold bg-clip-text bg-gradient-to-r from-indigo-500 via-indigo-800 to-indigo-400 text-transparent mb-2">
                        {title}
                    </h3>

                    {/* Price Section with Fixed Height */}
                    <div className="text-center mb-4 h-20 flex flex-col justify-center">
                        {price.amount !== 'Contact Sales' ? (
                            <>
                                <span className="text-4xl font-bold text-gray-900 ">
                                    {price.amount}
                                </span>
                                <span className="text-gray-500 ">
                                    {price.period}
                                </span>
                            </>
                        ) : (
                            <span className="bg-clip-text bg-gradient-to-r from-indigo-600 via-indigo-800 to-indigo-500 text-transparent text-2xl font-bold">
                                {price.amount}
                            </span>
                        )}
                    </div>

                    {/* Description */}
                    <p className="text-gray-600  text-center mb-6 min-h-[80px]">
                        {description}
                    </p>

                    {/* Button */}
                    <a
                        href={buttonLink}
                        onClick={() => navigate('/talk-to-founders')}
                        className={`text-lg w-full py-3 px-4 rounded-lg flex items-center justify-center gap-2 font-semibold transition-colors duration-300 ${popular
                            ? 'bg-indigo-600 text-white hover:bg-indigo-700'
                            : 'bg-indigo-200 text-indigo-700 hover:bg-indigo-300'
                            }`}
                    >
                        {/* Conditionally render the icon for "Contact Sales" or "Contact Us" */}
                        {(buttonText === "Contact Sales" || buttonText === "Contact Us") && (
                            <MdPhone className="w-5 h-5" />
                        )}
                        {buttonText}
                    </a>
                </div>

                {/* Features List Below Button */}
                <ul className="p-6 space-y-4">
                    {features.map((feature, index) => (
                        <li key={index} className="flex items-center">


                            {feature.icon === "check" ? (
                                <FiCheck className="text-indigo-500 w-5 h-5 flex-shrink-0 mr-3" />
                            ) : feature.icon === "info" ? (
                                <FiInfo className="text-indigo-500 w-5 h-5 flex-shrink-0 mr-3" />
                            ) : (
                                <FiX className="text-red-500 w-5 h-5 flex-shrink-0 mr-3" />
                            )}
                            <span className="text-gray-700  text-sm">
                                {feature.text}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        </motion.div>
    );
};