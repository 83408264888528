// src/components/CallToActionSection.js
import React from "react";
import { motion } from "framer-motion";

const CallToActionSection = () => {
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.7, ease: "easeOut" },
    },
  };

  return (
    <motion.section
      className="pb-16 md:pb-20 max-w-[90rem] mx-auto px-4 py-16 sm:px-6 lg:px-8 lg:py-20 relative isolate overflow-hidden mb-16 mt-8 sm:mb-8 sm:mt-8"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.2 }}
      variants={containerVariants}
    >
      <motion.div
        className="relative isolate overflow-hidden bg-gray-900 px-8 pt-24 shadow-lg rounded-3xl sm:px-20 md:pt-28 lg:flex lg:gap-x-20 lg:px-28 lg:pt-8 bg-dark-grid"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.2 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        {/* Updated SVG Background with Original Gradient */}
        <svg
          viewBox="0 0 1024 1024"
          className="group-hover:scale-120 absolute bottom-1/2 right-[60px] -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [maskImage:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-[790px] transition duration-1000"
          aria-hidden="true"
        >
          <circle
            cx="512"
            cy="512"
            r="512"
            fill="#9333ea"
            fillOpacity="0.3"
          ></circle>
          <defs>
            <radialGradient id="radial_radial1">
              <stop stopColor="#2600ff"></stop>
              <stop offset="1" stopColor="#5638ff"></stop>
            </radialGradient>
          </defs>
        </svg>

        {/* Content */}
        <motion.div
          className="mx-auto max-w-3xl text-center py-14"
          variants={itemVariants}
        >
          <h2 className="text-3xl font-semibold tracking-tight text-white sm:text-4xl">
            <span>
              <span className="block text-base font-normal text-gray-300">
                Get started
              </span>
              <span className="text-4xl font-semibold mb-2 bg-clip-text bg-gradient-to-r from-gray-50 via-indigo-200 to-gray-100 text-transparent">
                Let's change your recruitment process
              </span>
            </span>
          </h2>
          <p className="mt-2 text-lg text-gray-400">
            Complete your sign-up in under 2 minutes
          </p>
          <motion.div
            className="mt-10 flex flex-col items-center gap-6"
            variants={itemVariants}
          >
            <a
              href={`${window.location.origin}/early-access`}
              className="rounded-md bg-indigo-600 px-6 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition duration-300"
            >
              Join Waitlist
            </a>
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.section>
  );
};

export default CallToActionSection;
