// src/components/AgenticFrameworkSection.js
import React from "react";
import { motion } from "framer-motion";
import { AiOutlineDatabase, AiFillRocket, AiOutlineLineChart } from "react-icons/ai";

const AgenticFrameworkSection = () => {
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.7, ease: "easeOut" },
    },
  };

  return (
    <motion.section
      className="relative py-12 sm:py-16 md:py-24 bg-white"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.2 }}
      variants={containerVariants}
    >
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <motion.div
          className="text-center mb-12 sm:mb-16"
          variants={containerVariants}
        >
          <h2 className="mb-2 text-md font-semibold uppercase bg-clip-text bg-gradient-to-r from-indigo-400 via-indigo-800 to-indigo-400 text-transparent">
            Streamline
          </h2>
          <motion.h2
            className="text-3xl sm:text-4xl font-bold mb-4"
            variants={itemVariants}
          >
            Agentic-Based Framework
          </motion.h2>
          <motion.p
            className="text-gray-600 max-w-2xl mx-auto px-4"
            variants={itemVariants}
          >
            Streamlining Processes through an Agentic-Based Framework
          </motion.p>
        </motion.div>
        <motion.div
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
        >
          {/* Data Integration */}
          <motion.div
            className="relative p-6 rounded-2xl bg-gradient-to-br from-gray-50 to-white shadow-lg border border-gray-100"
            variants={itemVariants}
          >
            <AiOutlineDatabase className="w-10 h-10 text-[#8B75E5] mb-4" />
            <h3 className="text-xl font-semibold mb-2">Data Integration:</h3>
            <p className="text-gray-600">
              Seamlessly connects with HR systems to access data and streamline
              processes.
            </p>
          </motion.div>

          {/* AI Automation */}
          <motion.div
            className="relative p-6 rounded-2xl bg-gradient-to-br from-gray-50 to-white shadow-lg border border-gray-100"
            variants={itemVariants}
          >
            <AiFillRocket className="w-10 h-10 text-[#8B75E5] mb-4" />
            <h3 className="text-xl font-semibold mb-2">AI Automation:</h3>
            <p className="text-gray-600">
              Automates routine HR tasks using advanced machine learning.
            </p>
          </motion.div>

          {/* Actionable Insights */}
          <motion.div
            className="relative p-6 rounded-2xl bg-gradient-to-br from-gray-50 to-white shadow-lg border border-gray-100"
            variants={itemVariants}
          >
            <AiOutlineLineChart className="w-10 h-10 text-[#8B75E5] mb-4" />
            <h3 className="text-xl font-semibold mb-2">Actionable Insights:</h3>
            <p className="text-gray-600">
              Provides data-driven insights for better talent acquisition and
              management decisions.
            </p>
          </motion.div>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default AgenticFrameworkSection;
