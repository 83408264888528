import AccordionItem from "./AccordionItem"


export const FAQSection = () => {
    return (
        <div className="max-w-[85rem] lg:pt-16 pb-16 sm:pb-28 px-4 sm:px-6 lg:px-8 mx-auto">
            {/* Title */}
            <div className="max-w-2xl mx-auto text-center mb-10">
                <h2 className="text-2xl font-bold lg:text-3xl lg:leading-tight">Pricing FAQ</h2>
                <p className="mt-1 text-gray-500">Answers to the most frequently asked questions.</p>
            </div>
            {/* End Title */}

            <div className="max-w-4xl mx-auto">
                {/* List */}
                <div className="space-y-10">
                    {/* Subscription & Pricing Section */}
                    <div>


                        <div className="space-y-8">
                            <AccordionItem
                                id="can-i-cancel-anytime"
                                question="Can I cancel at anytime?"
                            >
                                <p className="mt-2 text-gray-700 ">
                                    Yes, you can cancel anytime. No questions are asked during the cancellation, but we would appreciate your feedback to improve our services.
                                </p>
                            </AccordionItem>

                            <AccordionItem
                                id="my-team-has-credits-how-do-we-use-them"
                                question="My team has credits. How do we use them?"
                            >
                                <p className="mt-2 text-gray-700 ">
                                    Each subscription plan includes a specific number of interviews. For example, the Starter plan includes 50 interviews, the Growth plan includes 250 interviews, and so on. You can use these credits to schedule and conduct interviews as part of your plan.
                                </p>
                            </AccordionItem>

                            <AccordionItem
                                id="how-does-your-pricing-work"
                                question="How does your pricing work?"
                            >
                                <p className="mt-2 text-gray-700 ">
                                    Our pricing is tiered to fit different business needs. The Starter plan begins at $159/month for 1 job posting and 50 interviews. The Growth plan is $399/month for 3 job postings and 250 interviews, along with additional features like access to our talent hub. Business and Enterprise plans offer even greater benefits, including more job postings, seats, integrations, and custom features.
                                </p>
                            </AccordionItem>

                            <AccordionItem
                                id="how-secure-is-your-platform"
                                question="How secure is your platform?"
                            >
                                <p className="mt-2 text-gray-700 ">
                                    Protecting the data you trust to us is our top priority. We use industry-standard encryption and rigorous security protocols to ensure your data and interviews remain confidential and secure.
                                </p>
                            </AccordionItem>

                            <AccordionItem
                                id="how-do-i-get-started-with-my-subscription"
                                question="How do I get started with my subscription?"
                            >
                                <p className="mt-2 text-gray-700 ">
                                    Once you subscribe to a plan, you will receive access to all features included in that tier. For example, Starter users get advanced interview analysis, while Growth users enjoy access to our talent hub and voice/language customization. If you're an Enterprise user, you'll receive dedicated support and custom integrations tailored to your needs.
                                </p>
                            </AccordionItem>

                            <AccordionItem
                                id="can-i-upgrade-my-plan"
                                question="Can I upgrade my plan if my needs change?"
                            >
                                <p className="mt-2 text-gray-700 ">
                                    Yes, you can upgrade your plan anytime. For example, if you outgrow the Starter plan, you can switch to Growth, Business, or Enterprise to unlock more job postings, interviews, and advanced features like API access and 24/7 support.
                                </p>
                            </AccordionItem>
                        </div>
                    </div>
                    {/* End Subscription & Pricing Section */}
                </div>
                {/* End List */}


                {/* End Card */}
            </div>
        </div>
    );
};