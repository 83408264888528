import React, { Suspense, lazy } from "react";
import { motion } from "framer-motion"; // Import Framer Motion
import AnnouncementBanner from "./components/AnnouncementBanner";
import TitleSection from "./components/TitleSection";
import FeaturesSection from "./components/FeaturesSection";
import AgenticFrameworkSection from "./components/AgenticFrameworkSection";
import CallToActionSection from "./components/CallToActionSection";
import { Helmet } from "react-helmet";
import { PricingCardSection } from "../ForRecruiterPage/Sections/PricingPlan/components/PricingCardSection";
import SearchAgents from "./components/SearchAgents";

// Lazy-loaded components
const VideoSection = lazy(() => import("./components/VideoSection"));
const Testimonials = lazy(() => import("./components/Testimonials"));

const fadeInUp = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

const FeaturePage = () => {
  return (
    <>
      {/* Page Metadata */}
      <Helmet>
        <title>Features ─ Asendia AI</title>
        <meta
          name="description"
          content="Explore Asendia AI's powerful features: AI-driven interviews, agentic frameworks, real-time insights, and more to enhance recruitment and job preparation."
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Features – Asendia AI" />
        <meta
          property="og:description"
          content="Discover the features of Asendia AI: AI-driven interviews, insights, and frameworks to enhance hiring and job preparation."
        />
        <meta property="og:url" content="https://www.asendia.ai/features" />
        <meta
          property="og:image"
          content="https://www.asendia.ai/images/social_sharing_banner2.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Features – Asendia AI" />
        <meta
          name="twitter:description"
          content="Explore the features of Asendia AI: AI-driven interviews, frameworks, and insights to enhance recruitment and job preparation."
        />
        <meta
          name="twitter:image"
          content="https://www.asendia.ai/images/social_sharing_banner2.png"
        />
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Features – Asendia AI",
            "description": "Explore Asendia AI's powerful features: AI-driven interviews, agentic frameworks, real-time insights, and more to enhance recruitment and job preparation.",
            "url": "https://www.asendia.ai/features",
            "image": "https://www.asendia.ai/images/social_sharing_banner2.png",
            "publisher": {
              "@type": "Organization",
              "name": "Asendia AI",
              "logo": "https://www.asendia.ai/images/asendia_logo_icon.png"
            }
          }
          `}
        </script>
      </Helmet>

      {/* Page Sections */}
      <AnnouncementBanner />

      {/* Title Section */}

      <TitleSection />


      {/* Video Section */}
      <Suspense fallback={<div></div>}>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          variants={fadeInUp}
        >
          <VideoSection />
        </motion.div>
      </Suspense>

    

      <div className="my-10 w-[80%] mx-auto h-[1px] bg-gray-200/0"></div>

      {/* Features Section */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        variants={fadeInUp}
      >
        <FeaturesSection />
      </motion.div>

      {/* Agentic Framework Section */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        variants={fadeInUp}
      >
        <AgenticFrameworkSection />
      </motion.div>

      {/* Search Agents */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        variants={fadeInUp}
      >
        <SearchAgents />
      </motion.div>

      <div className="my-10 w-[80%] mx-auto h-[1px] bg-gray-200/0"></div>

      {/* Pricing Card Section */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        variants={fadeInUp}
        className="overflow-hidden bg-gray-50 py-4 sm:py-4"
      >
        <PricingCardSection />
      </motion.div>

      {/* Call to Action Section */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        variants={fadeInUp}
      >
        <CallToActionSection />
      </motion.div>
    </>
  );
};

export default FeaturePage;
