import React, { useState } from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { MdDataSaverOff } from "react-icons/md";
import { FiDatabase } from "react-icons/fi";
import { IoShareSocialOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';





export const PlanComparisonTable = () => {
    const plans = [
        { name: "Starter", buttonText: "Get Started" },
        { name: "Growth", buttonText: "Get Started" },
        { name: "Business", buttonText: "Get Started" },
        { name: "Enterprise", buttonText: "Contact Sales", buttonLink: "/talk-to-founders" },
    ];

    const compareData = [
        {
            sectionTitle: "Financial Data",
            icon: <MdDataSaverOff className="text-indigo-500 w-6 h-6 mr-2" />,
            features: [
                { label: "1 job posting", included: [true, false, false, true] },
                { label: "3 job postings", included: [false, true, false, true] },
                { label: "10 job postings", included: [false, false, true, true] },
                { label: "Unlimited job postings", included: [false, false, false, true] },
                { label: "Interviews Included", included: [true, true, true, true] },
                { label: "Seats Included", included: [false, false, true, true] },
                { label: "ATS Integrations", included: [false, false, true, true] },
                {
                    label: "Early access to new features and discounts",
                    included: [false, false, false, true],
                },
            ],
        },
        {
            sectionTitle: "On-chain Data",
            icon: <FiDatabase className="text-indigo-500 w-6 h-6 mr-2" />,
            features: [
                {
                    label: "Change interview voice and language",
                    included: [false, true, true, true],
                },
                { label: "Customer support", included: [false, true, true, true] },
            ],
        },
        {
            sectionTitle: "Social Data",
            icon: <IoShareSocialOutline className="text-indigo-500 w-6 h-6 mr-2" />,
            features: [
                {
                    label: "Advanced analysis of interviews (summary, scoring, and more)",
                    included: [true, true, true, true],
                },
                {
                    label: "Dedicated recruiting support",
                    included: [false, false, true, true],
                },
                {
                    label: "Create interviews via Asendia AI API",
                    included: [false, false, false, true],
                },
            ],
        },
    ];

    const navigate = useNavigate();

    return (
        <div className="max-w-7xl mx-auto px-4 py-12">
            {/* Title */}
            <div className="max-w-2xl mx-auto text-center mb-16">
                <h2 className="text-2xl font-bold lg:text-3xl lg:leading-tight ">
                    Compare Plans
                </h2>
                <p className="mt-1 text-gray-500 ">
                    Quickly see which plan offers which features.
                </p>
            </div>

            {/* Responsive Table Container */}
            <div className="overflow-x-auto">
                <table className="min-w-full table-fixed border-collapse">
                    {/* Table Header */}
                    <thead className="bg-white sticky top-0 z-10">
                        <tr>
                            <th className="w-1/5 px-4 py-2 text-left border-b border-gray-200 bg-white">
                                {/* Empty header for feature labels */}
                            </th>
                            {plans.map((plan, index) => (
                                <th
                                    key={index}
                                    className={`px-4 py-2 text-center font-semibold text-lg border-b border-gray-200 ${plan.name === "Growth" ? "bg-indigo-100" : "bg-white"
                                        }`}
                                >
                                    <div className="flex flex-col items-center text-xl text-gray-800">
                                        {/* Plan Title */}
                                        <span>{plan.name}</span>

                                        {/* Modern Button */}
                                        <button
                                            className={`mt-4 mb-4 px-6 py-2 rounded-full border text-sm font-medium shadow transition ${plan.name === "Enterprise"
                                                ? "bg-gray-100 text-gray-800 hover:bg-gray-200"
                                                : plan.name === "Business"
                                                    ? "bg-white text-indigo-700 hover:bg-gray-100"
                                                    : plan.name === "Growth"
                                                        ? "bg-indigo-700 text-gray-100 hover:bg-indigo-500"
                                                        : "bg-white text-indigo-700 hover:bg-gray-100"
                                                }`}
                                            onClick={() => {
                                                if (plan.buttonLink) {
                                                    navigate(plan.buttonLink);
                                                }
                                            }}
                                        >
                                            {plan.buttonText}
                                        </button>
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        {compareData.map((section, sectionIndex) => (
                            <React.Fragment key={sectionIndex}>
                                {/* Section Title Row */}
                                <tr className="bg-gray-50">
                                    <td
                                        colSpan={plans.length + 1}
                                        className="px-4 py-6 border-t border-gray-300"
                                    >
                                        <div className="flex items-center">
                                            {/* Relevant Icon */}
                                            {section.icon}
                                            <h4 className="text-lg font-bold text-black">
                                                {section.sectionTitle}
                                            </h4>
                                        </div>
                                    </td>
                                </tr>

                                {/* Feature Rows */}
                                {section.features.map((feature, featureIndex) => (
                                    <tr key={featureIndex} className="bg-white">
                                        {/* Feature Label */}
                                        <td className="px-4 py-6 border-b border-gray-200 text-gray-700">
                                            {feature.label}
                                        </td>

                                        {/* Plan Inclusions */}
                                        {feature.included.map((isIncluded, planIndex) => (
                                            <td
                                                key={planIndex}
                                                className={`px-4 py-3 border-b border-gray-200 text-center ${planIndex === 1 ? "bg-indigo-50" : "bg-white"
                                                    }`}
                                            >
                                                {isIncluded ? (
                                                    <FaCheckCircle className="text-indigo-500 w-5 h-5 mx-auto" />
                                                ) : (
                                                    <span className="text-gray-300">—</span>
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};