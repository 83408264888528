// Install framer-motion and react-router-dom if you haven't already:
// npm install framer-motion react-router-dom

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { MdOutlineSearch, MdOutlineLocalShipping, MdOutlinePeopleAlt } from 'react-icons/md';

import meta from "../../../assets/metaLogo.png";
import aws from "../../../assets/amazon.png";
import google from "../../../assets/google.png";

import squarebg from "../../../assets/square-bg.png";

// Animation Variants
const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            when: "beforeChildren",
            staggerChildren: 0.2,
        },
    },
};

const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.6,
            ease: "easeOut",
        },
    },
};

// Updated Templates Array with Consistent Structure
const templates = [
    {
        id: "google-googlyness",
        category: 1, // FAANG Interviews
        icon: google,
        templateType: "Mock Interview Template",
        title: "Google’s Googliness",
        description: "Showcase how you align with Google’s culture of innovation and teamwork.",
        difficulty: "Medium",
        time: "10m",
        locked: false,
        gradient: {
            from: "rgba(66, 133, 244, 0.4)", // Official Blue with 40% opacity
            via: "rgba(216, 216, 216, 0.4)", // Light Grey with 40% opacity
            to: "rgba(216, 216, 216, 0.4)",  // Light Grey with 40% opacity
        },
        size: { height: 50, width: 50 },
    },
    {
        id: "amazon-leadership-principles",
        category: 1, // FAANG Interviews
        icon: aws,
        templateType: "Mock Interview Template",
        title: "Amazon’s Leadership Principles",
        description: "Test your familiarity with Amazon's Leadership Principles.",
        difficulty: "Medium",
        time: "10m",
        locked: false,
        gradient: {
            from: "rgba(255, 153, 0, 0.6)", // Official Orange with 60% opacity
            via: "rgba(216, 216, 216, 0.4)", // Light Grey with 40% opacity
            to: "rgba(216, 216, 216, 0.4)",  // Light Grey with 40% opacity
        },
        size: { height: 50, width: 50 },
    },
    {
        id: "meta-interview",
        category: 1, // FAANG Interviews
        icon: meta,
        templateType: "Mock Interview Template",
        title: "Meta Interview",
        description: "Prepare for Meta's unique interview process.",
        difficulty: "Medium",
        time: "10m",
        locked: false, // This can remain false or be set to true based on your logic
        gradient: {
            from: "rgba(24, 119, 242, 0.4)", // Official Blue with 40% opacity
            via: "rgba(216, 216, 216, 0.4)", // Light Grey with 40% opacity
            to: "rgba(216, 216, 216, 0.4)",  // Light Grey with 40% opacity
        },
        size: { height: 50, width: 50 },
    },
    // Add more templates as needed
];

const Templates = () => {
    const navigate = useNavigate();

    // Handler Function
    const handleRunTemplate = (template) => {
        console.log("template", template);

        if (template.templateType === "Mock Interview Template") {
            // Serialize the template object into query parameters
            const queryParams = new URLSearchParams({ 
                id: template.id, 
                title: template.title, 
                from: "landingPage"
            }).toString();

            console.log("queryParams", queryParams);

            // Redirect with query parameters
            window.open(`https://jobseeker.asendia.ai/?${queryParams}`, '_blank');
            // window.open(`http://localhost:3000/?${queryParams}`, '_blank');
            // window.open(`http://dev.dashboard.faangai.com/?${queryParams}`, '_blank');
            return;
        }

        if (!template.locked) {
            const slug = slugify(template.title);
            navigate(`/templates/${slug}`);
        } else {
            console.log("Template is locked.");
        }
    };

    return (
        <motion.section
            className="flex flex-col gap-6 py-12 2xl:py-16 max-w-screen-3xl mx-auto w-full px-3 sm:px-8 lg:px-16 xl:px-10"
            variants={sectionVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
        >
            {/* Existing Top Interview Practice Templates */}
            <div>
                <div className="text-center mb-10">
                    <h1 className="font-bold text-3xl lg:text-4xl text-gray-900">
                        Our Top Interview <span className="text-indigo-700">Practice</span> Templates
                    </h1>
                    <p className="mt-4 text-lg text-gray-700 ">
                        Choose from 100+ expert-vetted interviews, get feedback, and land your dream job.
                    </p>
                </div>
                <div className="grid w-full grid-flow-row gap-x-0 gap-y-6 sm:justify-items-center md:grid-cols-2 md:justify-items-start md:gap-6 lg:grid-cols-3 mt-8">
                    {templates.map((template) => (
                        <motion.div
                            key={template.id}
                            className="flex flex-col p-2 items-start overflow-hidden rounded-3xl border border-slate-200 shadow-md hover:shadow-lg transition-shadow duration-300"
                            variants={cardVariants}
                        >
                            {/* Gradient Background with Subtle White Overlay */}
                            <div
                                className="relative min-h-[280px] w-full flex items-center justify-center mb-4 p-4 rounded-2xl cursor-pointer"
                                style={{
                                    background: `
                        linear-gradient(to bottom right, ${template.gradient.from}, ${template.gradient.via}, ${template.gradient.to}),
                        rgba(255, 255, 255, 0.1)
                    `,
                                    backgroundBlendMode: 'lighten', // Using 'lighten' for a softer overlay
                                    backdropFilter: 'blur(8px)', // Gentle blur effect
                                }}
                                onClick={() => handleRunTemplate(template)}
                            >
                                {/* Optional: Remove the Square Background Image for a Cleaner Look */}
                                {/* <img
                                    src={squarebg}
                                    alt="Background"
                                    className="absolute inset-0 w-full h-full object-cover opacity-10"
                                /> */}

                                {/* Overlay for Icon */}
                                <div
                                    className="relative p-4 rounded-full shadow-lg flex items-center justify-center group transition-transform duration-300 hover:scale-105"
                                    style={{
                                        width: '90px',
                                        height: '90px',
                                        background: 'rgba(255, 255, 255, 0.2)', // Lighter background for the icon
                                        boxShadow:
                                            '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                    }}
                                >
                                    <img
                                        src={template.icon}
                                        className="w-12 h-12 transition-transform duration-300 group-hover:scale-110"
                                        style={{
                                            filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15))',
                                        }}
                                        alt={`${template.title} icon`}
                                    />
                                </div>
                            </div>
                            {/* Content Section */}
                            <div className="flex flex-col items-start gap-4 px-4 py-6 md:gap-6 md:px-8 flex-1">
                                <p className="text-2xl font-semibold tracking-tight">{template.title}</p>
                                <p className="text-base text-slate-500">{template.description}</p>
                            </div>
                            {/* Button Section */}
                            <div className="flex items-end justify-end px-4 py-4 md:px-8 w-full">
                                {template.id === 'meta-interview' ? (
                                    <button
                                        type="button"
                                        disabled
                                        className="inline-flex items-center justify-center rounded-lg bg-gray-200 text-gray-500 px-4 py-2 text-sm font-semibold cursor-not-allowed"
                                    >
                                        Coming Soon
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        onClick={() => handleRunTemplate(template)}
                                        disabled={template.locked}
                                        className={`group inline-flex items-center justify-center whitespace-nowrap rounded-lg align-middle text-sm font-semibold leading-none transition-all duration-300 ease-in-out ${
                                            template.locked
                                                ? 'cursor-not-allowed stroke-slate-400 text-slate-400'
                                                : 'stroke-blue-700 text-blue-700 hover:stroke-blue-950 hover:text-blue-950'
                                        } h-[42px] min-w-[42px] gap-2`}
                                    >
                                        <span>Run Template</span>
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="size-6 stroke-inherit"
                                        >
                                            <path
                                                d="M11 16L15 12L11 8"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            ></path>
                                            <circle cx="12" cy="12" r="9"></circle>
                                        </svg>
                                    </button>
                                )}
                            </div>
                        </motion.div>
                    ))}
                </div>

                {/* See More Templates Button */}
                {/* Uncomment and implement if needed */}
                {/* <motion.div
                    className="flex justify-center mt-8"
                    variants={cardVariants}
                // You can adjust the delay for the button if needed
                >
                    <button
                        type="button"
                        className="inline-flex items-center justify-center rounded-full bg-white text-indigo-700 px-6 py-3 text-lg font-semibold hover:bg-indigo-100 transition duration-300"
                        onClick={handleSeeMore}
                    >
                        View More Templates
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            xmlns="http://www.w3.org/2000/svg"
                            className="ml-2"
                        >
                            <path
                                d="M9 5L15 12L9 19"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            ></path>
                        </svg>
                    </button>
                </motion.div> */}
            </div>
        </motion.section>
    );
};

// Utility Function: Slugify (ensure you have this function)
const slugify = (text) => {
    return text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');            // Trim - from end of text
};

// Example handlers (if needed)
const handleReadMore = (id) => {
    console.log(`Read more about template ID: ${id}`);
    // Implement your navigation or modal logic here
};

const handleSeeMore = () => {
    console.log("See more templates");
    // Implement your navigation logic here
};

export default Templates;
