import React, { useState } from 'react';


const AccordionItem = ({ id, question, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="hs-accordion" id={id}>
            <button
                className="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-start text-gray-800 disabled:opacity-50 disabled:pointer-events-none focus:outline-none"
                aria-expanded={isOpen}
                aria-controls={`${id}-heading`}
                onClick={() => setIsOpen(!isOpen)}
            >
                {question}
                <svg
                    className={`hs-accordion-active:-rotate-180 transition-transform duration-300 size-4 ${isOpen ? '-rotate-180' : 'rotate-0'
                        }`}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="m6 9 6 6 6-6"></path>
                </svg>
            </button>
            <div
                id={`${id}-heading`}
                className={`hs-accordion-content overflow-hidden transition-all duration-300 ${isOpen ? 'max-h-screen' : 'max-h-0'
                    }`}
                role="region"
                aria-labelledby={id}
            >
                <div className="pt-3 pb-7 relative border-b border-gray-200">
                    {children}
                </div>
            </div>
        </div>
    );
};



export default AccordionItem;