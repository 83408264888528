// src/Pages/CookieSettings/CookieSettings.js

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { motion } from "framer-motion"; // Optional: For animations

const CookieSettings = ({ setPreferences }) => {
  const [preferences, setLocalPreferences] = useState({
    essential: true,
    analytics: false,
    marketing: false,
  });

  useEffect(() => {
    const storedPreferences = Cookies.get("cookiePreferences");
    if (storedPreferences) {
      try {
        const parsedPreferences = JSON.parse(decodeURIComponent(storedPreferences));
        setLocalPreferences(parsedPreferences);
      } catch (error) {
        console.error("Failed to parse cookiePreferences:", error);
      }
    }
  }, []);

  const handleChange = (e) => {
    const { name, checked } = e.target;
    setLocalPreferences((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setPreferences(preferences);
    Cookies.set("cookiePreferences", encodeURIComponent(JSON.stringify(preferences)), {
      path: "/",
      expires: 365, // days
    });
    alert("Your preferences have been saved!");
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center px-4">
      <motion.div
        className="bg-white shadow-xl rounded-2xl max-w-md w-full p-8"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-3xl font-semibold text-gray-800 mb-6 text-center">
          Manage Cookie Preferences
        </h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Essential Cookies */}
          <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
            <label className="flex items-center">
              <input
                type="checkbox"
                name="essential"
                checked={true}
                disabled
                aria-disabled="true"
                className="w-6 h-6 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 cursor-not-allowed"
              />
              <span className="ml-4 text-gray-700 font-medium">
                Essential Cookies
              </span>
            </label>
            <p className="mt-2 text-sm text-gray-500">
              These cookies are necessary for the website to function and cannot
              be switched off in our systems.
            </p>
          </div>

          {/* Analytics Cookies */}
          <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
            <label className="flex items-center">
              <input
                type="checkbox"
                name="analytics"
                checked={preferences.analytics}
                onChange={handleChange}
                aria-checked={preferences.analytics}
                className="w-6 h-6 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
              />
              <span className="ml-4 text-gray-700 font-medium">
                Analytics Cookies
              </span>
            </label>
            <p className="mt-2 text-sm text-gray-500">
              These cookies help us understand how visitors interact with our
              website by collecting and reporting information anonymously.
            </p>
          </div>

          {/* Marketing Cookies */}
          <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
            <label className="flex items-center">
              <input
                type="checkbox"
                name="marketing"
                checked={preferences.marketing}
                onChange={handleChange}
                aria-checked={preferences.marketing}
                className="w-6 h-6 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
              />
              <span className="ml-4 text-gray-700 font-medium">
                Marketing Cookies
              </span>
            </label>
            <p className="mt-2 text-sm text-gray-500">
              These cookies are used to deliver personalized advertisements to
              you based on your browsing activities.
            </p>
          </div>

          {/* Action Buttons */}
          <div className="flex justify-between items-center mt-6">
            <button
              type="button"
              onClick={() => {
                setLocalPreferences({
                  essential: true,
                  analytics: false,
                  marketing: false,
                });
              }}
              className="text-gray-600 hover:text-indigo-600 transition duration-200 focus:outline-none"
              aria-label="Reset to Default Preferences"
            >
              Reset to Defaults
            </button>
            <button
              type="submit"
              className="bg-indigo-600 text-white px-6 py-2 rounded-lg hover:bg-indigo-700 transition transform hover:scale-105 duration-200 focus:outline-none"
              aria-label="Save Cookie Preferences"
            >
              Save Preferences
            </button>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

CookieSettings.propTypes = {
  setPreferences: PropTypes.func.isRequired,
};

export default CookieSettings;
