// TrustedByProfessionals.jsx
import React, { useState } from 'react';
import { motion, useViewportScroll, useTransform } from 'framer-motion';

// Main Component
const SearchAgents = () => {
    // Enhanced Data for the CV cards
    const cvs = [
        {
            id: 1,
            name: 'Alice Johnson',
            title: 'Frontend Developer',
            role: 'Full Stack Developer',
            experience: '5+ years',
            skills: ['HTML', 'CSS', 'JavaScript', 'React', 'Node.js', 'TypeScript'],
            education: 'B.Tech in Information Technology, UC Berkeley (2015 - 2019)',
            projects: [
                'Designed and developed responsive web applications used by 500K+ users.',
                'Created RESTful APIs for mobile applications, improving backend performance.',
                'Redesigned database structures to improve query efficiency by 30%.',
            ],
            imageSrc: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?crop=faces&fit=crop&w=256&h=256&q=80',
            imageAlt: 'Alice Johnson - Frontend Developer',
            CandidateSummary:
                "I have a strong foundation in frontend and backend development, with a focus on creating responsive and high-performance applications. My approach combines technical expertise with user-centric design principles.",
        },
        {
            id: 2,
            name: 'David Lewis',
            title: 'Machine Learning Specialist',
            role: 'Data Scientist',
            experience: '3+ years',
            skills: ['Python', 'TensorFlow', 'Data Visualization', 'SQL', 'R'],
            education: 'M.Sc. in Data Science, MIT (2018 - 2020)',
            projects: [
                'Developed predictive models that increased sales forecasting accuracy by 20%.',
                'Implemented machine learning algorithms to analyze customer behavior patterns.',
                'Built automated reporting tools that reduced manual effort by 40%.',
            ],
            imageSrc: 'https://i.ibb.co/PWk3pct/review-02.jpg',
            imageAlt: 'David Lewis - Machine Learning Specialist',
            CandidateSummary:
                "With a strong background in data science and machine learning, I transform complex datasets into actionable insights. I excel at predictive modeling, statistical analysis, and creating data-driven solutions.",
        },
        {
            id: 3,
            name: 'Sarah Martinez',
            title: 'Software Engineer',
            role: 'Full Stack Developer',
            experience: '5+ years',
            skills: ['JavaScript', 'React', 'Node.js', 'MongoDB', 'Express'],
            education: 'B.Sc. in Computer Science, Stanford University (2015 - 2019)',
            projects: [
                'Developed a scalable e-commerce platform handling 1M+ users monthly.',
                'Integrated social media APIs for seamless user interaction.',
                'Built a real-time analytics dashboard using React and Node.js.',
            ],
            imageSrc: 'https://images.unsplash.com/photo-1520813792240-56fc4a3765a7?crop=faces&fit=crop&w=256&h=256&q=80',
            imageAlt: 'Sarah Martinez - Software Engineer',
            CandidateSummary:
                "As a seasoned full-stack developer, I specialize in building scalable web applications and creating intuitive user experiences. My expertise in modern frameworks and databases has consistently driven successful project outcomes.",
        },
        {
            id: 4,
            name: 'Michael Roberts',
            title: 'Cybersecurity Expert',
            role: 'DevOps Engineer',
            experience: '6+ years',
            skills: ['AWS', 'Docker', 'Kubernetes', 'Terraform', 'CI/CD'],
            education: 'MBA in Information Security, Harvard University (2014 - 2016)',
            projects: [
                'Optimized cloud infrastructure, reducing costs by 25%.',
                'Implemented secure CI/CD pipelines ensuring compliance with industry standards.',
                'Automated security audits, improving response times for vulnerabilities.',
            ],
            imageSrc: 'https://i.ibb.co/55Jth3z/review-03.jpg',
            imageAlt: 'Michael Roberts - Cybersecurity Expert',
            CandidateSummary:
                "As a DevOps and cybersecurity expert, I focus on automating infrastructure and enhancing system security. My experience spans cloud platforms, container orchestration, and regulatory compliance.",
        },
        {
            id: 5,
            name: 'Bob Smith',
            title: 'Data Analyst',
            role: 'Data Scientist',
            experience: '3+ years',
            skills: ['R', 'Python', 'SQL', 'Tableau', 'Power BI'],
            education: 'B.Sc. in Statistics, University of Chicago (2016 - 2020)',
            projects: [
                'Analyzed market trends to support decision-making, boosting revenue by 15%.',
                'Built interactive dashboards for executive stakeholders using Tableau.',
                'Streamlined data cleaning processes, reducing analysis time by 35%.',
            ],
            imageSrc: 'https://i.ibb.co/PWk3pct/review-02.jpg',
            imageAlt: 'Bob Smith - Data Analyst',
            CandidateSummary:
                "I specialize in analyzing complex datasets to uncover insights that drive strategic business decisions. My expertise includes statistical modeling, data visualization, and process optimization.",
        },
        {
            id: 6,
            name: 'Carol White',
            title: 'Senior UX Designer',
            role: 'UI/UX Designer',
            experience: '4+ years',
            skills: ['Sketch', 'Figma', 'Adobe XD', 'User Research', 'Prototyping'],
            education: 'B.Des. in Graphic Design, Rhode Island School of Design (2014 - 2018)',
            projects: [
                'Designed intuitive user interfaces for a healthcare mobile app, increasing user retention by 25%.',
                'Conducted user research to inform design decisions, enhancing usability scores by 30%.',
                'Developed interactive prototypes for startups, reducing development timelines by 20%.',
            ],
            imageSrc: 'https://i.ibb.co/55Jth3z/review-03.jpg',
            imageAlt: 'Carol White - Senior UX Designer',
            CandidateSummary:
                "As a senior UX designer, I create user-centered designs that deliver both functionality and aesthetic appeal. My process involves extensive research, prototyping, and iterative improvements to meet user needs.",
        },
        {
            id: 7,
            name: 'Emma Carter',
            title: 'Project Manager',
            role: 'Project Manager',
            experience: '7+ years',
            skills: ['Agile', 'Scrum', 'Team Leadership', 'Budget Management', 'Risk Assessment'],
            education: 'MBA in Project Management, Wharton School (2013 - 2015)',
            projects: [
                'Managed projects with budgets exceeding $2M, achieving a 95% on-time delivery rate.',
                'Led cross-functional teams of 20+ members to deliver high-priority initiatives.',
                'Implemented Agile methodologies to improve team productivity by 30%.',
            ],
            imageSrc: 'https://i.ibb.co/55Jth3z/review-03.jpg',
            imageAlt: 'Emma Carter - Project Manager',
            CandidateSummary:
                "As a seasoned project manager, I specialize in aligning business goals with technology solutions. My expertise lies in Agile methodologies, team management, and delivering projects on time and within budget.",
        },
        {
            id: 8,
            name: 'Liam Brooks',
            title: 'Mobile Developer',
            role: 'Mobile Developer',
            experience: '5+ years',
            skills: ['Swift', 'Kotlin', 'Flutter', 'React Native', 'UI/UX Design'],
            education: 'B.Sc. in Software Engineering, University of Toronto (2014 - 2018)',
            projects: [
                'Developed cross-platform mobile apps with 4.8+ app store ratings.',
                'Integrated third-party APIs to enhance app functionality.',
                'Optimized mobile app performance, reducing load times by 40%.',
            ],
            imageSrc: 'https://i.ibb.co/55Jth3z/review-03.jpg',
            imageAlt: 'Liam Brooks - Mobile Developer',
            CandidateSummary:
                "As a mobile developer with extensive experience, I create innovative, user-friendly apps tailored to client needs. My expertise in both native and cross-platform technologies ensures optimal performance and seamless user experiences.",
        },
    ];


    // Predefined search suggestions
    const searchSuggestions = [
        { role: 'Full Stack Developer', experience: '5+ years' },
        { role: 'Data Scientist', experience: '3+ years' },
        { role: 'UI/UX Designer', experience: '4+ years' },
        { role: 'DevOps Engineer', experience: '6+ years' },
        { role: 'Project Manager', experience: '7+ years' },
        { role: 'Mobile Developer', experience: '5+ years' },
    ];

    // State for filters
    const [selectedRole, setSelectedRole] = useState('All');
    const [selectedExperience, setSelectedExperience] = useState('All');

    // Filtered CVs based on selected filters
    const filteredCvs = cvs.filter((cv) => {
        const roleMatch = selectedRole === 'All' || cv.role === selectedRole;
        const experienceMatch =
            selectedExperience === 'All' || cv.experience === selectedExperience;
        return roleMatch && experienceMatch;
    });

    // Handler for suggestion click
    const handleSuggestionClick = (suggestion) => {
        setSelectedRole(suggestion.role);
        setSelectedExperience(suggestion.experience);
    };

    // Handler to reset filters
    const handleResetFilters = () => {
        setSelectedRole('All');
        setSelectedExperience('All');
    };

    // Framer Motion Hooks for Parallax Effect on Background Circles
    const { scrollY } = useViewportScroll();
    const y1 = useTransform(scrollY, [0, 800], [0, -100]);
    const y2 = useTransform(scrollY, [0, 800], [0, 100]);

    return (
        <section className="relative py-24 bg-white overflow-hidden">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                {/* Header Section with Fade-In Animation */}
                <motion.div
                    className="text-center mb-14"
                    initial={{ opacity: 0, y: -50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    viewport={{ once: true }}
                >
                     <h2
            className="mb-2 text-md font-semibold uppercase bg-clip-text bg-gradient-to-r from-indigo-400 via-indigo-800 to-indigo-400 text-transparent"
          >
            Assistant
          </h2>
                    <h2 className="text-4xl font-bold mb-4 text-gray-900">
                        Candidate Screening Assistant
                    </h2>
                    <p className="text-gray-600 max-w-2xl mx-auto">
                        AI-powered screening algorithms evaluate resumes and applications based on relevant criteria, saving time and reducing bias.
                    </p>
                </motion.div>

                {/* Search/Filter Section with Slide-Up Animation */}
                <motion.div
                    className="flex flex-col items-center mb-12 space-y-8"
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.2 }}
                    viewport={{ once: true }}
                >
                    {/* Disabled Search Bar */}
                    <div className="w-full max-w-md relative">
                        <input
                            type="text"
                            value={
                                selectedRole === "All" && selectedExperience === "All"
                                    ? ""
                                    : `${selectedRole} - ${selectedExperience}`
                            }
                            placeholder="Search for professionals..."
                            disabled
                            className="w-full pl-4 pr-12 py-3 bg-white/50 backdrop-blur-md text-gray-500 placeholder-gray-300 rounded-full focus:outline-none cursor-not-allowed shadow-sm border border-gray-200"
                        />
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="absolute right-4 top-1/2 transform -translate-y-1/2 w-6 h-6 text-gray-400"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M21 21l-4.35-4.35m0 0A7.5 7.5 0 1111.25 4.5a7.5 7.5 0 016.4 12.15z"
                            />
                        </svg>
                    </div>

                    {/* Search Suggestions */}
                    <div className="flex flex-wrap justify-center gap-4 max-w-3xl mx-auto">
                        {searchSuggestions.map((suggestion, index) => (
                            <motion.button
                                key={index}
                                onClick={() => handleSuggestionClick(suggestion)}
                                className="border flex items-center space-x-2 px-4 py-2 bg-white text-indigo-700 text-sm rounded-lg shadow-md hover:bg-gray-100 hover:shadow-lg transition-transform transform hover:scale-105"
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                <span className="font-medium">{suggestion.role}</span>
                                <span className="bg-indigo-100 px-2 py-1 text-xs rounded-full">
                                    {suggestion.experience}
                                </span>
                            </motion.button>
                        ))}
                        {/* Reset Button */}
                        {(selectedRole !== "All" || selectedExperience !== "All") && (
                            <motion.button
                                onClick={handleResetFilters}
                                className="border border-red flex items-center px-4 py-2 bg-red-200 text-red-700 text-sm rounded-lg shadow-md hover:bg-red-100 hover:shadow-lg transition-transform transform hover:scale-105"
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                Reset Filters
                            </motion.button>
                        )}
                    </div>
                </motion.div>

                {/* Background Blur Circles with Parallax Effect */}
                <div className="relative">
                    <motion.div
                        className="absolute left-0 top-0 h-[500px] w-[500px] rounded-full bg-[#8B75E5]/10 blur-3xl"
                        style={{ y: y1 }}
                    ></motion.div>
                    <motion.div
                        className="absolute right-0 top-0 h-[500px] w-[500px] rounded-full bg-[#4f46e51c]/10 blur-3xl"
                        style={{ y: y2 }}
                    ></motion.div>

                    {/* CV Cards Container (Stack) with Fade-In Animation */}
                    <motion.div
                        className="relative flex justify-center px-8 py-24"
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ duration: 1 }}
                        viewport={{ once: true }}
                    >
                        <div className="relative w-full max-w-5xl h-[600px]">
                            {filteredCvs.length > 0 ? (
                                // Only show the first SIX, stacked
                                filteredCvs.slice(0, 6).map((cv, index) => (
                                    <StackedCard key={cv.id} cv={cv} index={index} />
                                ))
                            ) : (
                                <p className="text-gray-500 text-center col-span-full">
                                    No professionals match your criteria.
                                </p>
                            )}
                        </div>
                    </motion.div>
                </div>
            </div>
        </section>
    );
};

// StackedCard Component with Scroll-Triggered Animations
const StackedCard = ({ cv, index }) => {
    const { imageSrc, imageAlt, name, title, role, experience, skills, education, projects, CandidateSummary } = cv;

    // Slight custom offsets/rotations for each card in the stack
    const cardOffsets = [
        { x: '-35%', rotate: -6 },
        { x: '-17%', rotate: -3 },
        { x: '0%', rotate: 0 },
        { x: '17%', rotate: 3 },
        { x: '34%', rotate: 6 },
        { x: '43%', rotate: 14 },
    ];

    return (
        <motion.div
            className="absolute top-0 left-1/2 bg-white rounded-3xl shadow-lg p-4 w-[390px] h-fit cursor-grab hover:shadow-xl transition-shadow duration-300 overflow-hidden"
            style={{
                x: cardOffsets[index]?.x || '0%',
                rotate: cardOffsets[index]?.rotate || 0,
                zIndex: 10 - index,
                translateX: '-50%',
            }}
            drag
            dragConstraints={{ left: -100, right: 100, top: -100, bottom: 100 }}
            whileTap={{ cursor: 'grabbing' }}
            whileDrag={{
                scale: 1.05,
                boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15)',
            }}
            initial={{ opacity: 0, y: 40 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 * index }}
            viewport={{ once: true }}
        >
            {/* Header Section */}
            <div className="flex items-center mb-4">
                <img
                    src={imageSrc}
                    alt={imageAlt}
                    className="h-16 w-16 rounded-full border-2 border-indigo-500 object-cover"
                />
                <div className="ml-3">
                    <h3 className="text-lg font-bold text-gray-900">{name}</h3>
                    <p className="text-gray-600 text-xs">{title}</p>
                    <p className="text-indigo-500 text-xs font-medium">{role}</p>
                </div>
            </div>

            {/* Divider */}
            <hr className="border-gray-200 my-3" />

            {/* Quote Section */}
            <blockquote className="text-gray-600 italic text-xs mb-4 px-3 py-2 bg-gray-50 rounded-md shadow-sm">
                “{CandidateSummary}”
            </blockquote>

            {/* Skills Section */}
            <div className="mb-4">
                <h4 className="text-md font-semibold text-gray-800 flex items-center mb-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 text-indigo-500 mr-1"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M8 9l3 3-3 3m5-6l3 3-3 3"
                        />
                    </svg>
                    Skills
                </h4>
                <div className="flex flex-wrap gap-2">
                    {skills.map((skill, idx) => (
                        <span
                            key={idx}
                            className="px-2 py-1 text-xs font-medium bg-indigo-100 text-indigo-800 rounded-full"
                        >
                            {skill}
                        </span>
                    ))}
                </div>
            </div>

            {/* Education Section */}
            <div className="mb-4">
                <h4 className="text-md font-semibold text-gray-800 flex items-center mb-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 text-indigo-500 mr-1"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 14l9-5-9-5-9 5 9 5z"
                        />
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 14l6.16-3.422a12.083 12.083 0 01-6.16 5.882m0 0a12.083 12.083 0 01-6.16-5.882L12 14zm0 0v6"
                        />
                    </svg>
                    Education
                </h4>
                <p className="text-gray-700 text-xs px-3 py-2 bg-gray-50 rounded-md shadow-sm">
                    {education}
                </p>
            </div>

            {/* Projects Section */}
            <div>
                <h4 className="text-md font-semibold text-gray-800 flex items-center mb-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 text-indigo-500 mr-1"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9.75 15.25l-3-3m0 0l3-3m-3 3h12.5m-12.5 0a7.5 7.5 0 1112.5 6.5"
                        />
                    </svg>
                    Projects
                </h4>
                <ul className="list-disc list-inside text-gray-700 text-xs px-3 py-2 bg-gray-50 rounded-md shadow-sm">
                    {projects.map((project, idx) => (
                        <li key={idx}>{project}</li>
                    ))}
                </ul>
            </div>
        </motion.div>
    );
};

export default SearchAgents;
