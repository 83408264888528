import axios from 'axios';

export const storeWaitlistUserData = async (userData) => {
  const apiUrl = 'https://www.asendia.ai/api/store-waitlist-user-data'; // Replace with your actual API URL

  try {
    const response = await axios.post(apiUrl, userData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Handle successful response
    console.log('Response:', response.data);
    return response.data; // Return the response data for further processing
  } catch (error) {
    // Handle errors
    if (error.response) {
      // Server-side error
      console.error('Error:', error.response.data);
      throw error.response.data; // Throw the error for the caller to handle
    } else if (error.request) {
      // No response from the server
      console.error('No response received:', error.request);
      throw new Error('No response from server');
    } else {
      // Error setting up the request
      console.error('Axios Error:', error.message);
      throw new Error(error.message);
    }
  }
};


export const storePartnerData = async (partnerData) => {
  const apiUrl = 'https://www.asendia.ai/api'; 
  try {
      const response = await fetch(`${apiUrl}/store-partner-data`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(partnerData),
      });

      const result = await response.json();

      if (!response.ok) {
          throw new Error(result.message || 'Failed to store partner data.');
      }

      return result; // Contains status, message, and data
  } catch (error) {
      console.error('Error storing partner data:', error.message);
      throw error;
  }
};


export const storeCustomerData = async (partnerData) => {
  const apiUrl = 'https://jobseeker.asendia.ai/api'; 
  try {
      const response = await fetch(`${apiUrl}/store-community-partner-data`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(partnerData),
      });

      const result = await response.json();

      if (!response.ok) {
          throw new Error(result.message || 'Failed to store partner data.');
      }

      return result; // Contains status, message, and data
  } catch (error) {
      console.error('Error storing partner data:', error.message);
      throw error;
  }
};

