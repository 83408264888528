// src/Components/NewsletterSection/NewsletterSection.jsx

import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';

// Replace with your actual API base URL
const API_BASE_URL = 'https://www.asendia.ai/api'; // Example: 'http://localhost:5000/api'

const NewsletterSection = () => {
    const location = useLocation();
    const currentRoute = location.pathname;

    const [email, setEmail] = useState('');
    const [status, setStatus] = useState({ message: '', type: '' });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    const animationVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeOut" } },
    };

    useEffect(() => {
        const hasSubscribed = localStorage.getItem('hasSubscribed');
        if (!hasSubscribed) {
            setIsVisible(true);
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Basic email format validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email || !emailRegex.test(email)) {
            setStatus({ message: 'Please enter a valid email address.', type: 'error' });
            return;
        }

        setIsSubmitting(true);
        setStatus({ message: '', type: '' });

        try {
            const response = await fetch(`${API_BASE_URL}/subscribe-newsletter`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    route: currentRoute, // Include the current route
                }),
            });

            const data = await response.json();

            if (response.status === 201) {
                setStatus({ message: data.message, type: 'success' });
                setEmail(''); // Clear the input field upon success

                // Store subscription status and email in Local Storage
                localStorage.setItem('hasSubscribed', true);
                localStorage.setItem('subscriberEmail', email);
                setIsVisible(false); // Hide the newsletter section
            } else if (response.status === 409) {
                setStatus({ message: data.message, type: 'error' });
            } else if (response.status === 400) {
                setStatus({ message: data.error, type: 'error' });
            } else {
                setStatus({ message: 'Something went wrong. Please try again later.', type: 'error' });
            }
        } catch (error) {
            console.error('Error subscribing to newsletter:', error);
            setStatus({ message: 'Network error. Please try again later.', type: 'error' });
        } finally {
            setIsSubmitting(false);
        }
    };

   

    return (
        <motion.div
            className="relative isolate overflow-hidden bg-gray-900 mb-16 sm:mb-15 rounded-[30px] group"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={animationVariants}
        >
            <svg
                viewBox="0 0 1024 1024"
                aria-hidden="true"
                className="absolute left-1/2 top-1/2 -z-10 size-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
            >
                <circle
                    r={512}
                    cx={512}
                    cy={512}
                    fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
                    fillOpacity="0.7"
                />
                <defs>
                    <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                        <stop stopColor="#7775D6" />
                        <stop offset={1} stopColor="#5e419e" />
                    </radialGradient>
                </defs>
            </svg>

            <div className="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:py-10 lg:px-8">
                <div className="px-6 py-6 rounded-lg md:py-12 md:px-12 lg:py-16 lg:px-8 xl:flex xl:gap-x-8">
                    <div className="xl:w-1/2">
                        <h2 className="text-3xl font-normal leading-8 tracking-tight text-white sm:text-4xl sm:leading-9 text-balance">
                            Stay Ahead with Asendia's Latest Blockbuster Updates
                        </h2>
                        <p
                            className="max-w-3xl mt-3 text-md mb-2 font-thin leading-6 text-indigo-200 text-balance"
                            id="newsletter-headline"
                        >
                            Sign up for our newsletter and be the first to uncover new features, updates, and exclusive Asendia hacks.
                        </p>
                        {/* Remove or update the GitHub link as needed */}
                        {/* <a
                            target="_blank"
                            rel="noindex nofollow noopener noreferrer"
                            href="https://github.com/your-repo" // Update the href to your GitHub or relevant link
                            className="inline-flex"
                        >
                            <div className="flex mt-2 transition delay-500 duration-1000 -translate-x-4 opacity-0 group-hover:opacity-100 group-hover:translate-x-0 will-change-transform">
                                <div className="flex items-center px-3 py-0.5 bg-white border-t border-b border-l border-gray-400 rounded-l-lg cursor-pointer duration-300">
                                    <svg
                                        className="w-4 h-4 mr-1 text-gray-700"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                    </svg>
                                    <span className="self-center text-sm font-medium">Star</span>
                                </div>
                                <div className="px-3 py-0.5 text-sm font-semibold border border-t border-gray-400 rounded-r-lg bg-gray-100 cursor-pointer transition duration-300 hover:text-indigo-600 flex items-center hover:bg-gray-700 hover:text-white">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="mr-1 h-4 w-4"
                                        viewBox="0 0 1024 1024"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M511.6 76.3C264.3 76.2 64 276.4 64 523.5C64 718.9 189.3 885 363.8 946c23.5 5.9 19.9-10.8 19.9-22.2v-77.5c-135.7 15.9-141.2-73.9-150.3-88.9C215 726 171.5 718 184.5 703c30.9-15.9 62.4 4 98.9 57.9c26.4 39.1 77.9 32.5 104 26c5.7-23.5 17.9-44.5 34.7-60.8c-140.6-25.2-199.2-111-199.2-213c0-49.5 16.3-95 48.3-131.7c-20.4-60.5 1.9-112.3 4.9-120c58.1-5.2 118.5 41.6 123.2 45.3c33-8.9 70.7-13.6 112.9-13.6c42.4 0 80.2 4.9 113.5 13.9c11.3-8.6 67.3-48.8 121.3-43.9c2.9 7.7 24.7 58.3 5.5 118c32.4 36.8 48.9 82.7 48.9 132.3c0 102.2-59 188.1-200 212.9a127.5 127.5 0 0 1 38.1 91v112.5c.8 9 0 17.9 15 17.9c177.1-59.7 304.6-227 304.6-424.1c0-247.2-200.4-447.3-447.5-447.3"
                                        ></path>
                                    </svg>
                                    <span>GitHub</span>
                                </div>
                            </div>
                        </a> */}
                    </div>
                    <div className="xl:w-1/2 mt-6 xl:mt-0">
                        <form
                            className="flex flex-col sm:flex-row"
                            aria-labelledby="newsletter-headline"
                            onSubmit={handleSubmit}
                        >
                            <input
                                aria-label="Email address"
                                type="email"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full px-5 py-3 text-base leading-6 text-gray-900 placeholder-gray-500 transition duration-150 ease-in-out bg-white border border-transparent rounded-md appearance-none focus:outline-none focus:placeholder-gray-400"
                                placeholder="Enter your email"
                                disabled={isSubmitting}
                            />
                            <button
                                type="submit"
                                className={`mt-3 sm:mt-0 sm:ml-3 px-5 py-3 text-base font-medium leading-6 transition duration-300 ease-in-out rounded-md focus:outline-none ${isSubmitting
                                        ? 'bg-indigo-500 cursor-not-allowed'
                                        : 'bg-[#473886] text-white hover:bg-indigo-900 focus:bg-green-600'
                                    }`}
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? 'Subscribing...' : 'Subscribe'}
                            </button>
                        </form>
                        {status.message && (
                            <p
                                className={`mt-2 text-sm ${status.type === 'success'
                                        ? 'text-green-400'
                                        : 'text-red-400'
                                    }`}
                            >
                                {status.message}
                            </p>
                        )}
                        <p className="mt-1 text-xs leading-5 text-indigo-200">
                            Warning: reading our emails might make you dangerously productive! 🚀📩{' '}
                            {/* <span className="opacity-0 transition duration-1000 delay-1000 ease-in-out group-hover:opacity-100 text-gray-50">
                                🚀📩
                            </span> */}
                        </p>

                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default NewsletterSection;
