// src/Components/CookieConsentBanner/CookieConsentBanner.js

import React from "react";
import CookieConsent from "react-cookie-consent";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const CookieConsentBanner = ({ onAccept, onDecline, show }) => {
  if (!show) return null;

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept All"
      declineButtonText="Reject All"
      enableDeclineButton
      onAccept={onAccept}
      onDecline={onDecline}
      style={{
        background: "rgba(245, 245, 245, 0.6)",
        backdropFilter: "blur(22px)",
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
        borderTop: "1px solid rgba(0, 0, 0, 0.1)",
        padding: "13px",
        maxWidth:"96%",
        color: "#333",
        fontSize: "14px",
        fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
        borderRadius: "50px",
        margin: "0 20px 20px 30px",
      }}
      buttonStyle={{
        backgroundColor: "#2f174f",
        color: "#ffffff",
        borderRadius: "50px",
        padding: "12px 25px",
        fontSize: "14px",
        fontWeight: "bold",
        border: "none",
        cursor: "pointer",
        transition: "transform 0.2s ease, box-shadow 0.2s ease",
      }}
      declineButtonStyle={{
        backgroundColor: "#e0e0e0",
        color: "#333",
        borderRadius: "50px",
        padding: "12px 25px",
        fontSize: "14px",
        fontWeight: "bold",
        border: "none",
        cursor: "pointer",
        transition: "transform 0.2s ease, box-shadow 0.2s ease",
      }}
      buttonHoverStyle={{
        transform: "scale(1.05)",
        boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.3)",
      }}
      expires={365}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <p>
          We use cookies to provide you with a better experience. By accepting, you consent to
          our use of cookies for analytics and marketing. {" "}
          <Link
            to="/cookie-policy"
            style={{
              color: "#2f174f",
              textDecoration: "underline",
              fontWeight: "bold",
            }}
          >
            Manage Preferences
          </Link>
        </p>
      </div>
    </CookieConsent>
  );
};

CookieConsentBanner.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default CookieConsentBanner;
