// AsendiaAiPartnerForm.jsx

import React, { useState, useEffect } from 'react';
import { AiFillDownSquare } from 'react-icons/ai';
import { FaCheckCircle } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { motion } from 'framer-motion';
import asendialogo from "../../../assets/asendia_white_logo.png"; // Adjust the path as needed
import { storePartnerData } from '../../../service/Api';

export default function AsendiaAiPartnerForm() {
    const [formData, setFormData] = useState({
        companyName: '',
        firstName: '',
        lastName: '',
        companyWebsite: '',
        workEmail: '',
        country: '',
        companySize: '',
        industryExpertise: '',
        about: '',
        currentlyUsingAsendia: '',
        teamMembersDedicated: '',
        partnerWithOtherSaaS: '',
        numberOfCustomers: '',
        typicalCustomerSize: '',
        regions: [],
        partnershipOptions: [],
        revenueShare: '',
        existingDeal: '',
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submissionSuccess, setSubmissionSuccess] = useState(false);
    const [submissionError, setSubmissionError] = useState(null);

    // Custom styles for the progress bar and icon
    const customStylesSuccess = {
        "--toastify-color-progress-success": "#6366f1", // Indigo for progress bar
        "--toastify-icon-color-success": "#6366f1", // Indigo for the success icon
        width: "400px", // Increase width of the toast
    };

    const customStylesError = {
        "--toastify-color-progress-error": "#dc2626", // Red for progress bar
        "--toastify-icon-color-error": "#dc2626", // Red for the error icon
        width: "500px", // Increase width of the toast
    };

    // Handle changes for text, email, number, and textarea inputs
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // Handle changes for checkbox inputs
    const handleCheckboxChange = (e, fieldName) => {
        const { checked, value } = e.target;
        setFormData((prev) => {
            let updated = [...prev[fieldName]];
            if (checked) {
                updated.push(value);
            } else {
                updated = updated.filter((item) => item !== value);
            }
            return { ...prev, [fieldName]: updated };
        });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsSubmitting(true);
        setSubmissionSuccess(false);
        setSubmissionError(null);

        try {
            await storePartnerData(formData);
            setSubmissionSuccess(true);
            setFormData({
                companyName: '',
                firstName: '',
                lastName: '',
                companyWebsite: '',
                workEmail: '',
                country: '',
                companySize: '',
                industryExpertise: '',
                about: '',
                currentlyUsingAsendia: '',
                teamMembersDedicated: '',
                partnerWithOtherSaaS: '',
                numberOfCustomers: '',
                typicalCustomerSize: '',
                regions: [],
                partnershipOptions: [],
                revenueShare: '',
                existingDeal: '',
            });
            toast.success('Waitlist form submitted successfully!', { 
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false, 
                draggable: true,
                progress: undefined,
                theme: "light",
                style: customStylesSuccess, // Apply custom styles
            });
            // Scroll to top
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } catch (error) {
            setSubmissionError(error.message || 'An error occurred while submitting the form.');
            toast.error(error.message || 'An error occurred while submitting the form.', { 
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false, 
                draggable: true,
                progress: undefined,
                theme: "light",
                style: customStylesError, // Apply custom styles
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    // Framer Motion variants for animation
    const containerVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
    };

    return (
        <motion.div
            className="min-h-screen flex items-center justify-center bg-gradient-to-br from-white via-gray-100 via-white to-blue-50 px-4 py-10"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
        >
            {/* Toast Container */}
            <ToastContainer />

            {submissionSuccess ? (
                <div className="flex flex-col items-center justify-center text-center space-y-5 py-10 px-6 rounded-3xl border bg-white shadow-2xl">
                    <FaCheckCircle className="text-indigo-500 w-12 h-12" />
                    <h2 className="text-2xl font-semibold text-gray-800">
                        Thank You for Your Interest!
                    </h2>
                    <p className="text-gray-600 max-w-md">
                        We’ve successfully received your request to join our partnership program. Our team will review your submission and reach out with the next steps soon. Stay tuned!
                    </p>
                    <button
                        type="button"
                        onClick={() => setSubmissionSuccess(false)}
                        className="mt-6 px-6 py-3 text-sm font-medium text-white bg-gradient-to-r from-indigo-500 to-indigo-600 rounded-full shadow-lg hover:from-indigo-600 hover:to-indigo-700 focus:outline-none focus:ring-4 focus:ring-indigo-300 transition-transform duration-300 transform hover:scale-105"
                    >
                        Submit Another Request
                    </button>
                </div>

            ) : (
                <form
                    onSubmit={handleSubmit}
                    className="max-w-4xl w-full bg-white shadow-2xl border rounded-3xl p-8 sm:p-10"
                >
                    {/* Page header */}
                    <div className="relative bg-gradient-to-br from-purple-800 rounded-3xl bg-gradient-to-r from-[#45237b] via-[#1b1231] to-[#251A3D] text-white py-14 px-6 sm:px-10 lg:px-20 mb-12">
                        {/* Background overlay with subtle gradient */}
                        <div className="absolute inset-0 bg-gradient-to-t rounded-3xl from-black/30 to-transparent"></div>

                        {/* Decorative elements (optional) */}
                        <div className="absolute -bottom-10 -right-10 w-40 h-40 bg-gradient-to-br from-blue-500 to-indigo-600 rounded-full opacity-30 blur-3xl"></div>

                        {/* Content */}
                        <div className="relative text-center z-10">
                            {/* Logo */}
                            <img
                                src={asendialogo}
                                alt="Asendia Logo"
                                className="max-w-[10rem] sm:max-w-[7rem] mx-auto mb-5 opacity-90 hover:scale-105 transition-transform duration-300 ease-in-out"
                            />

                            {/* Main Heading */}
                            <h1 className="text-2xl sm:text-4xl font-bold bg-clip-text bg-gradient-to-r from-gray-50 via-indigo-200 to-gray-50 text-transparent tracking-tight">
                                Join the Asendia AI Partnership Program
                            </h1>

                            {/* Supporting Text */}
                            <p className="mt-4 text-md sm:text-lg text-gray-200 max-w-3xl mx-auto">
                                Unlock the potential of collaboration. Fill out the form below and let us tailor our resources and initiatives to empower your business.
                            </p>
                        </div>
                    </div>

                    {/* Display submission error message */}
                    {submissionError && (
                        <div className="mb-6 p-4 text-red-800 bg-red-100 border border-red-200 rounded">
                            {submissionError}
                        </div>
                    )}

                    {/* SECTION 1: Basic Company Info */}
                    <div className="border-b border-gray-200 pb-8 mb-8">
                        <h2 className="text-xl font-semibold text-gray-800">Basic Company Info</h2>
                        <p className="mt-1 text-sm text-gray-600">
                            Provide your core business details.
                        </p>
                        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-5 sm:grid-cols-6">
                            {/* Company Name */}
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="companyName"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Company Name <span className="text-red-500">*</span>
                                </label>
                                <input
                                    id="companyName"
                                    name="companyName"
                                    type="text"
                                    required
                                    value={formData.companyName}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-sm text-gray-800 placeholder-gray-400 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                />
                            </div>

                            {/* Company Website/LinkedIn */}
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="companyWebsite"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Company Website/LinkedIn <span className="text-red-500">*</span>
                                </label>
                                <input
                                    id="companyWebsite"
                                    name="companyWebsite"
                                    type="url"
                                    required
                                    value={formData.companyWebsite}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-sm text-gray-800 placeholder-gray-400 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                    placeholder="https://www.yourcompany.com"
                                />
                            </div>

                            {/* Country */}
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="country"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Country <span className="text-red-500">*</span>
                                </label>
                                <div className="relative mt-1">
                                    <select
                                        id="country"
                                        name="country"
                                        required
                                        value={formData.country}
                                        onChange={handleChange}
                                        className="block w-full appearance-none rounded-md border border-gray-300 bg-white px-3 py-2 pr-9 text-sm text-gray-800 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                    >
                                        <option value="">-- Select a country --</option>
                                        <option>United States</option>
                                        <option>Canada</option>
                                        <option>United Kingdom</option>
                                        <option>Australia</option>
                                        <option>Other</option>
                                    </select>
                                    <AiFillDownSquare
                                        className="pointer-events-none absolute top-2 right-3 h-4 w-4 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </div>
                            </div>

                            {/* Work Email Address */}
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="workEmail"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Your Work Email <span className="text-red-500">*</span>
                                </label>
                                <input
                                    id="workEmail"
                                    name="workEmail"
                                    type="email"
                                    required
                                    value={formData.workEmail}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-sm text-gray-800 placeholder-gray-400 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                />
                            </div>

                            {/* First Name */}
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="firstName"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    First Name <span className="text-red-500">*</span>
                                </label>
                                <input
                                    id="firstName"
                                    name="firstName"
                                    type="text"
                                    required
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-sm text-gray-800 placeholder-gray-400 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                />
                            </div>

                            {/* Last Name */}
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="lastName"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Last Name <span className="text-red-500">*</span>
                                </label>
                                <input
                                    id="lastName"
                                    name="lastName"
                                    type="text"
                                    required
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-sm text-gray-800 placeholder-gray-400 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                />
                            </div>
                        </div>
                    </div>

                    {/* SECTION 2: Company Details */}
                    <div className="border-b border-gray-200 pb-8 mb-8">
                        <h2 className="text-xl font-semibold text-gray-800">Company Details</h2>
                        <p className="mt-1 text-sm text-gray-600">
                            Tell us more about your organization.
                        </p>
                        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-5 sm:grid-cols-6">
                            {/* Company Size */}
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="companySize"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Company Size <span className="text-red-500">*</span>
                                </label>
                                <div className="relative mt-1">
                                    <select
                                        id="companySize"
                                        name="companySize"
                                        required
                                        value={formData.companySize}
                                        onChange={handleChange}
                                        className="block w-full appearance-none rounded-md border border-gray-300 bg-white px-3 py-2 pr-9 text-sm text-gray-800 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                    >
                                        <option value="">-- Select --</option>
                                        <option>1-10</option>
                                        <option>11-25</option>
                                        <option>26-50</option>
                                        <option>51-100</option>
                                        <option>101-200</option>
                                        <option>201-500</option>
                                        <option>500+</option>
                                    </select>
                                    <AiFillDownSquare
                                        className="pointer-events-none absolute top-2 right-3 h-4 w-4 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </div>
                            </div>

                            {/* Industry Expertise */}
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="industryExpertise"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Industry Expertise <span className="text-red-500">*</span>
                                </label>
                                <div className="relative mt-1">
                                    <select
                                        id="industryExpertise"
                                        name="industryExpertise"
                                        required
                                        value={formData.industryExpertise}
                                        onChange={handleChange}
                                        className="block w-full appearance-none rounded-md border border-gray-300 bg-white px-3 py-2 pr-9 text-sm text-gray-800 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                    >
                                        <option value="">-- Select --</option>
                                        <option>Technology</option>
                                        <option>Consulting</option>
                                        <option>Marketing</option>
                                        <option>Finance</option>
                                        <option>Healthcare</option>
                                        <option>Other</option>
                                    </select>
                                    <AiFillDownSquare
                                        className="pointer-events-none absolute top-2 right-3 h-4 w-4 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </div>
                            </div>

                            {/* About */}
                            <div className="col-span-full">
                                <label
                                    htmlFor="about"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    About Your Business <span className="text-red-500">*</span>
                                </label>
                                <textarea
                                    id="about"
                                    name="about"
                                    rows={4}
                                    required
                                    value={formData.about}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-sm text-gray-800 placeholder-gray-400 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                    placeholder="Describe your business, goals, and capabilities."
                                />
                            </div>
                        </div>
                    </div>

                    {/* SECTION 3: Partnership Details */}
                    <div className="border-b border-gray-200 pb-8 mb-8">
                        <h2 className="text-xl font-semibold text-gray-800">Partnership Details</h2>
                        <p className="mt-1 text-sm text-gray-600">
                            Help us understand how you plan to work with Asendia AI.
                        </p>
                        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-5 sm:grid-cols-6">
                            {/* Currently using Asendia.ai? */}
                            <div className="sm:col-span-3">
                                <fieldset>
                                    <legend className="block text-sm font-medium text-gray-700 mb-1">
                                        Currently using Asendia.ai? <span className="text-red-500">*</span>
                                    </legend>
                                    <div className="flex items-center space-x-4 mt-1">
                                        <label className="inline-flex items-center text-sm text-gray-700">
                                            <input
                                                type="radio"
                                                name="currentlyUsingAsendia"
                                                value="Yes"
                                                required
                                                checked={formData.currentlyUsingAsendia === 'Yes'}
                                                onChange={handleChange}
                                                className="mr-1 focus:ring-indigo-500"
                                            />
                                            Yes
                                        </label>
                                        <label className="inline-flex items-center text-sm text-gray-700">
                                            <input
                                                type="radio"
                                                name="currentlyUsingAsendia"
                                                value="No"
                                                required
                                                checked={formData.currentlyUsingAsendia === 'No'}
                                                onChange={handleChange}
                                                className="mr-1 focus:ring-indigo-500"
                                            />
                                            No
                                        </label>
                                    </div>
                                </fieldset>
                            </div>

                            {/* Team members to dedicate */}
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="teamMembersDedicated"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Team Members Dedicated <span className="text-red-500">*</span>
                                </label>
                                <input
                                    id="teamMembersDedicated"
                                    name="teamMembersDedicated"
                                    type="number"
                                    min="0"
                                    required
                                    value={formData.teamMembersDedicated}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-sm text-gray-800 placeholder-gray-400 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                    placeholder="e.g., 5"
                                />
                            </div>

                            {/* Do you partner with any other SaaS? */}
                            <div className="sm:col-span-3">
                                <fieldset>
                                    <legend className="block text-sm font-medium text-gray-700 mb-1">
                                        Other SaaS Partnerships <span className="text-red-500">*</span>
                                    </legend>
                                    <div className="flex items-center space-x-4 mt-1">
                                        <label className="inline-flex items-center text-sm text-gray-700">
                                            <input
                                                type="radio"
                                                name="partnerWithOtherSaaS"
                                                value="Yes"
                                                required
                                                checked={formData.partnerWithOtherSaaS === 'Yes'}
                                                onChange={handleChange}
                                                className="mr-1 focus:ring-indigo-500"
                                            />
                                            Yes
                                        </label>
                                        <label className="inline-flex items-center text-sm text-gray-700">
                                            <input
                                                type="radio"
                                                name="partnerWithOtherSaaS"
                                                value="No"
                                                required
                                                checked={formData.partnerWithOtherSaaS === 'No'}
                                                onChange={handleChange}
                                                className="mr-1 focus:ring-indigo-500"
                                            />
                                            No
                                        </label>
                                    </div>
                                </fieldset>
                            </div>

                            {/* Number of customers */}
                            <div className="sm:col-span-3">
                                <label className="block text-sm font-medium text-gray-700">
                                    Number of Customers <span className="text-red-500">*</span>
                                </label>
                                <div className="relative mt-1">
                                    <select
                                        name="numberOfCustomers"
                                        required
                                        value={formData.numberOfCustomers}
                                        onChange={handleChange}
                                        className="block w-full appearance-none rounded-md border border-gray-300 bg-white px-3 py-2 pr-9 text-sm text-gray-800 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                    >
                                        <option value="">-- Select --</option>
                                        <option>1-10</option>
                                        <option>11-25</option>
                                        <option>26-50</option>
                                        <option>51-100</option>
                                        <option>101-300</option>
                                        <option>300+</option>
                                    </select>
                                    <AiFillDownSquare
                                        className="pointer-events-none absolute top-2 right-3 h-4 w-4 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </div>
                            </div>

                            {/* Typical customer size */}
                            <div className="sm:col-span-3">
                                <label className="block text-sm font-medium text-gray-700">
                                    Typical Customer Size <span className="text-red-500">*</span>
                                </label>
                                <div className="relative mt-1">
                                    <select
                                        name="typicalCustomerSize"
                                        required
                                        value={formData.typicalCustomerSize}
                                        onChange={handleChange}
                                        className="block w-full appearance-none rounded-md border border-gray-300 bg-white px-3 py-2 pr-9 text-sm text-gray-800 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                    >
                                        <option value="">-- Select --</option>
                                        <option>1-50</option>
                                        <option>51-100</option>
                                        <option>101-200</option>
                                        <option>201-500</option>
                                        <option>501-1000</option>
                                        <option>1000+</option>
                                    </select>
                                    <AiFillDownSquare
                                        className="pointer-events-none absolute top-2 right-3 h-4 w-4 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </div>
                            </div>

                            {/* Regions to work with (checkboxes) */}
                            <div className="sm:col-span-3">
                                <label className="block text-sm font-medium text-gray-700">
                                    Regions You Wish to Work With <span className="text-red-500">*</span>
                                </label>
                                <div className="mt-1 flex flex-col space-y-2">
                                    <label className="inline-flex items-center text-sm text-gray-700">
                                        <input
                                            type="checkbox"
                                            name="regions"
                                            value="North America"
                                            required={formData.regions.length === 0}
                                            checked={formData.regions.includes('North America')}
                                            onChange={(e) => handleCheckboxChange(e, 'regions')}
                                            className="mr-1 focus:ring-indigo-500"
                                        />
                                        North America
                                    </label>
                                    <label className="inline-flex items-center text-sm text-gray-700">
                                        <input
                                            type="checkbox"
                                            name="regions"
                                            value="Europe"
                                            required={formData.regions.length === 0}
                                            checked={formData.regions.includes('Europe')}
                                            onChange={(e) => handleCheckboxChange(e, 'regions')}
                                            className="mr-1 focus:ring-indigo-500"
                                        />
                                        Europe
                                    </label>
                                    <label className="inline-flex items-center text-sm text-gray-700">
                                        <input
                                            type="checkbox"
                                            name="regions"
                                            value="Asia"
                                            required={formData.regions.length === 0}
                                            checked={formData.regions.includes('Asia')}
                                            onChange={(e) => handleCheckboxChange(e, 'regions')}
                                            className="mr-1 focus:ring-indigo-500"
                                        />
                                        Asia
                                    </label>
                                    {/* Add more regions as needed */}
                                </div>
                            </div>

                            {/* Partnership Options (checkboxes) */}
                            <div className="sm:col-span-3">
                                <label className="block text-sm font-medium text-gray-700">
                                    Partnership Options <span className="text-red-500">*</span>
                                </label>
                                <div className="mt-1 flex flex-col space-y-2">
                                    <label className="inline-flex items-center text-sm text-gray-700">
                                        <input
                                            type="checkbox"
                                            name="partnershipOptions"
                                            value="Referral"
                                            required={formData.partnershipOptions.length === 0}
                                            checked={formData.partnershipOptions.includes('Referral')}
                                            onChange={(e) => handleCheckboxChange(e, 'partnershipOptions')}
                                            className="mr-1 focus:ring-indigo-500"
                                        />
                                        Referral
                                    </label>
                                    <label className="inline-flex items-center text-sm text-gray-700">
                                        <input
                                            type="checkbox"
                                            name="partnershipOptions"
                                            value="Reseller"
                                            required={formData.partnershipOptions.length === 0}
                                            checked={formData.partnershipOptions.includes('Reseller')}
                                            onChange={(e) => handleCheckboxChange(e, 'partnershipOptions')}
                                            className="mr-1 focus:ring-indigo-500"
                                        />
                                        Reseller
                                    </label>
                                    <label className="inline-flex items-center text-sm text-gray-700">
                                        <input
                                            type="checkbox"
                                            name="partnershipOptions"
                                            value="Implementation"
                                            required={formData.partnershipOptions.length === 0}
                                            checked={formData.partnershipOptions.includes('Implementation')}
                                            onChange={(e) => handleCheckboxChange(e, 'partnershipOptions')}
                                            className="mr-1 focus:ring-indigo-500"
                                        />
                                        Implementation
                                    </label>
                                    {/* Add more partnership options as needed */}
                                </div>
                            </div>

                            {/* Revenue from providing services */}
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="revenueShare"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Revenue from Services (%) <span className="text-red-500">*</span>
                                </label>
                                <input
                                    id="revenueShare"
                                    name="revenueShare"
                                    type="number"
                                    min="0"
                                    max="100"
                                    required
                                    value={formData.revenueShare}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-sm text-gray-800 placeholder-gray-400 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                    placeholder="e.g., 50"
                                />
                            </div>

                            {/* Existing deal on the line? */}
                            <div className="sm:col-span-3">
                                <fieldset>
                                    <legend className="block text-sm font-medium text-gray-700 mb-1">
                                        Do you have an Asendia.ai deal on the line? <span className="text-red-500">*</span>
                                    </legend>
                                    <div className="flex items-center space-x-4 mt-1">
                                        <label className="inline-flex items-center text-sm text-gray-700">
                                            <input
                                                type="radio"
                                                name="existingDeal"
                                                value="Yes"
                                                required
                                                checked={formData.existingDeal === 'Yes'}
                                                onChange={handleChange}
                                                className="mr-1 focus:ring-indigo-500"
                                            />
                                            Yes
                                        </label>
                                        <label className="inline-flex items-center text-sm text-gray-700">
                                            <input
                                                type="radio"
                                                name="existingDeal"
                                                value="No"
                                                required
                                                checked={formData.existingDeal === 'No'}
                                                onChange={handleChange}
                                                className="mr-1 focus:ring-indigo-500"
                                            />
                                            No
                                        </label>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>

                    {/* FORM ACTIONS */}
                    <div className="flex items-center justify-end gap-x-4">
                        <button
                            type="button"
                            onClick={() => window.location.reload()} // Simple cancel action: reload the page
                            className="flex items-center justify-center gap-x-2 rounded-full border border-gray-300 px-5 py-3 text-base font-medium text-gray-700 shadow-sm transition-all duration-200 hover:border-gray-400 hover:bg-gray-50 hover:shadow-md active:shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-300"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            disabled={isSubmitting}
                            className={`flex items-center justify-center gap-x-2 rounded-full bg-indigo-600 px-5 py-3 text-base font-semibold text-white shadow-md transition-all duration-200 hover:bg-indigo-500 hover:shadow-xl active:shadow-md focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-offset-2 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                        >
                            {isSubmitting ? (
                                <>
                                    <svg
                                        className="w-6 h-6 animate-spin text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                                        ></path>
                                    </svg>
                                    Submitting...
                                </>
                            ) : (
                                <>
                                    Submit
                                </>
                            )}
                        </button>
                    </div>
                </form>
            )}
        </motion.div>
    );
}
