// src/components/BlogDetail.jsx
import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import badisPicture from "../../../assets/badis_picture.jpg";
import NewsletterSection from '../../../Components/NewsLetter/NewsLetter';

// Sample blog data (In a real app, fetch this data from an API)
const blogContent = {
  'ai-agents-revolutionizing-recruitment': { // Blog Post ID 1
    title: 'From Chaos to Clarity: How AI Agents Are Helping Recruiters Save Time, Cut Costs, and Find the Best Talent Faster',
    description: 'As AI agents revolutionize recruitment, discover how they help recruiters save time, cut costs, and find the best talent faster.',
    author: {
      name: 'Badis Zormati',
      role: 'AI Engineer',
      imgSrc: badisPicture, // Ensure this is the correct image source
      date: 'Jan. 8, 2025',
    },
    mainImage: 'https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2Ff48f6697-17b0-42d8-815c-eeba2dcfc249_1128x686.png',
    content: (
      <>
        <p className="text-lg leading-8 text-gray-700 ">
          As we look ahead to 2025, the landscape of artificial intelligence is poised for transformative changes that will redefine how businesses operate. This post explores the anticipated rise of AI agents, their implications for recruitment, and why this evolution is not just inevitable but beneficial for businesses.
        </p>
        <p className="mt-4 text-base leading-7 text-gray-700 ">
          Recruitment is supposed to bring in the best talent to help businesses grow, but let’s be honest: the process often feels anything but efficient.
        </p>
        <p className="mt-4 text-base leading-7 text-gray-700 ">
          Sifting through endless resumes. Playing calendar tag to schedule interviews. Answering the same questions from candidates again and again. All of this eats away at time, energy, and focus, resources that hiring teams could spend on the one thing that matters most: hiring the right person for the job.
        </p>
        <p className="mt-4 text-base leading-7 text-gray-700 ">
          And when roles stay vacant for too long? The ripple effects are costly: teams stretch themselves thin to fill the gaps, projects get delayed, and productivity takes a hit. Meanwhile, competitors are moving faster, snagging top talent before you’ve even had a chance to respond.
        </p>
        <p className="mt-4 text-base leading-7 text-gray-700 ">
          This isn’t just frustrating, it’s unsustainable. But the good news is, the days of manual hiring chaos are coming to an end. The rise of <strong>AI agents</strong> is transforming recruitment, giving companies a way to hire smarter, faster, and with less hassle.
        </p>
        <figure className="mt-6">
          <img src="https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2Ff48f6697-17b0-42d8-815c-eeba2dcfc249_1128x686.png" alt="Transforming Recruitment with AI Agents" className="w-full rounded-2xl" />
          <figcaption className="mt-2 text-sm text-gray-500 ">Transforming Recruitment with AI Agents</figcaption>
        </figure>
        <h2 className="mt-8 text-2xl font-semibold text-gray-900 ">How AI Agents Are Changing the Game</h2>
        <p className="mt-4 text-base leading-7 text-gray-700 ">
          Imagine a recruitment process where you’re no longer bogged down by repetitive tasks. AI agents are designed to take on the heavy lifting, freeing up your team to focus on high-value activities like evaluating candidates and building relationships.
        </p>
        <p className="mt-4 text-base leading-7 text-gray-700 ">
          Here’s how they’re reshaping recruitment:
        </p>
        <h3 className="mt-6 text-xl font-semibold text-gray-800 ">Intelligent Resume Screening</h3>
        <p className="mt-2 text-base leading-7 text-gray-700 ">
          AI agents can scan and analyze thousands of resumes in seconds, identifying top candidates based on skills, experience, and even cultural fit. Unlike traditional keyword filters, these systems use advanced algorithms to pinpoint high-potential talent that might otherwise be overlooked.
        </p>
        <h3 className="mt-6 text-xl font-semibold text-gray-800 ">Seamless Interview Scheduling</h3>
        <p className="mt-2 text-base leading-7 text-gray-700 ">
          Forget Email ping-pong. AI agents can handle all the scheduling, syncing calendars, and providing candidates with available slots—all in real-time. This eliminates delays and ensures interviews are booked quickly and efficiently.
        </p>
        <h3 className="mt-6 text-xl font-semibold text-gray-800 ">Enhanced Candidate Engagement</h3>
        <p className="mt-2 text-base leading-7 text-gray-700 ">
          AI agents don’t just automate communication; they make it better. Candidates receive instant responses to questions, updates on their application status, and tailored follow-ups that keep them engaged throughout the process.
        </p>
        <h3 className="mt-6 text-xl font-semibold text-gray-800 ">Actionable Insights for Better Hires</h3>
        <p className="mt-2 text-base leading-7 text-gray-700 ">
          AI agents go beyond automation by providing data-driven insights:
        </p>
        <ul className="list-disc list-inside text-base leading-7 text-gray-700  mt-2">
          <li>Which candidates are most likely to succeed?</li>
          <li>What interview questions best reveal candidate strengths?</li>
          <li>How can the hiring process be optimized for speed and effectiveness?</li>
        </ul>
        <p className="mt-2 text-base leading-7 text-gray-700 ">
          With this kind of visibility, recruiters can make smarter, faster decisions and avoid costly mis-hires.
        </p>
        <figure className="mt-6">
          <img src="https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2Fce3a59f8-038d-4dad-991e-caaaee327cb1_1324x796.png" alt="Transforming Recruitment with AI Agents" className="w-full rounded-2xl" />
          <figcaption className="mt-2 text-sm text-gray-500 ">Transforming Recruitment with AI Agents</figcaption>
        </figure>
        <h2 className="mt-8 text-2xl font-semibold text-gray-900 ">Why 2025 Will Be the Year of AI-Powered Recruitment</h2>
        <p className="mt-4 text-base leading-7 text-gray-700 ">
          The inefficiencies of traditional hiring methods can’t keep up with the demands of today’s business world. Vacancies need to be filled faster, candidates expect a seamless experience, and companies need more accuracy in their hiring decisions.
        </p>
        <p className="mt-4 text-base leading-7 text-gray-700 ">
          AI agents meet these challenges head-on, transforming recruitment from a time-consuming slog into a streamlined, data-driven process that delivers results.
        </p>

        <h2 className="mt-8 text-2xl font-semibold text-gray-900 ">A Smarter Way to Hire with <em>Asendia AI</em></h2>
        <p className="mt-4 text-base leading-7 text-gray-700 ">
          If you’re ready to stop struggling with outdated methods and start hiring smarter, it’s time to explore <strong>Asendia AI</strong>.
        </p>
        <p className="mt-4 text-base leading-7 text-gray-700 ">
          Asendia AI offers more than just automation, it provides a complete solution for modern recruitment:
        </p>
        <ul className="list-disc list-inside text-base leading-7 text-gray-700  mt-2">
          <li>A <strong>premium AI interviewer</strong> that dynamically generates tailored questions during interviews, uncovering the true potential of candidates.</li>
          <li>A <strong>holistic assessment system</strong> that evaluates candidates based on skills, experience, and compatibility with your company culture.</li>
          <li>An <strong>exclusive database of evaluated and assessed candidates</strong>, giving you access to a curated talent pool ready to hit the ground running.</li>
          <li>Automation for the repetitive tasks that slow you down, like scheduling and screening, so you can focus on what really matters.</li>
        </ul>
        <p className="mt-4 text-base leading-7 text-gray-700 ">
          By partnering with <strong>Asendia AI</strong>, you’re not just keeping up with the future of recruitment—you’re leading it.
        </p>
        <figure className="mt-6">
          <img src="https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F3bbd0a6a-66d6-4ca6-a20d-b59d4076b28a_1240x1036.png" alt="Transforming Recruitment with AI Agents" className="w-full rounded-2xl" />
          <figcaption className="mt-2 text-sm text-gray-500 ">Transforming Recruitment with AI Agents</figcaption>
        </figure>
        <h2 className="mt-8 text-2xl font-semibold text-gray-900 ">Ready to Revolutionize Your Hiring Process?</h2>
        <p className="mt-4 text-base leading-7 text-gray-700 ">
          2025 is here. It’s time to embrace the tools that will help you find the best talent faster, smarter, and with less stress.
        </p>
        <p className="mt-4 text-base leading-7 text-gray-700 ">
          Discover how <strong>Asendia AI</strong> can transform your recruitment process and give your team the edge it needs to succeed.
        </p>
        <p className="mt-4 text-base leading-7 text-gray-700 ">
          <a href="https://asednia.ai" className="text-indigo-600 underline">
            Explore Asendia AI Today
          </a>.
        </p>
      </>
    ),
    relatedArticles: [
      {
        id: 'launch-asendia-ai-integrated-recruitment-ecosystem',
        title: "We're Launching Asendia AI: The Fully Integrated AI Ecosystem for Recruiters and Job Seekers! 🚀",
        imgSrc: "https://t9012173995.p.clickup-attachments.com/t9012173995/b073532b-f310-4507-97fd-622183cc0d80/We're%20Launching%20Asendia%20AI_%20The%20Fully%20Integrated%20AI%20Ecosystem%20for%20Recruiters%20and%20Job%20Seekers!%20%F0%9F%9A%80%20-%20visual%20selection.png",
        readTime: 'Read in 5 minutes',
      },
    ],
  },
  "launch-asendia-ai-integrated-recruitment-ecosystem": { // Blog Post ID 2
    title: "We're Launching Asendia AI: The Fully Integrated AI Ecosystem for Recruiters and Job Seekers! 🚀",
    description: 'Asendia AI introduces a groundbreaking platform that revolutionizes recruitment with end-to-end automation and agentic-based frameworks.',
    author: {
      name: 'Badis Zormati',
      role: 'AI Engineer',
      imgSrc: badisPicture, // Ensure this is the correct image source or use a different one if needed
      date: 'Jan. 20, 2025',
    },
    mainImage: 'https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fyour-main-image-url.com/image.png', // Replace with the actual main image URL
    content: (
      <>
        <p className="text-lg leading-8 text-gray-700 ">
          Asendia AI is thrilled to announce the launch of our groundbreaking platform designed to revolutionize the recruitment landscape. Our cutting-edge Generative AI platform automates HR tasks end-to-end, providing businesses and recruitment firms with an agentic-based framework that streamlines processes. This innovation liberates HR teams from repetitive tasks, allowing them to focus on fostering employee well-being, growth, and engagement, ultimately saving money and increasing efficiency.
        </p>
        <figure className="mt-6">
          <img src="https://t9012173995.p.clickup-attachments.com/t9012173995/b073532b-f310-4507-97fd-622183cc0d80/We're%20Launching%20Asendia%20AI_%20The%20Fully%20Integrated%20AI%20Ecosystem%20for%20Recruiters%20and%20Job%20Seekers!%20%F0%9F%9A%80%20-%20visual%20selection.png" alt="Transforming Recruitment with AI Agents" className="w-full rounded-2xl" />
          <figcaption className="mt-2 text-sm text-gray-500 ">Transforming Recruitment with AI Agents</figcaption>
        </figure>
        <h2 className="mt-8 text-2xl font-semibold text-gray-900 ">The Story Behind Asendia AI</h2>
        <h3 className="mt-6 text-xl font-semibold text-gray-800 ">The Inspiration</h3>
        <p className="mt-2 text-base leading-7 text-gray-700 ">
          The name "Asendia" is derived from the Latin word "asendere," meaning "to rise, climb, or soar." This reflects our core belief that everyone has the potential to overcome challenges, achieve their aspirations, and unlock their full potential. Whether it's a job seeker aiming for their dream role or a company in search of top talent, our mission is to provide the tools, insights, and opportunities to help them ascend.
        </p>

        <h3 className="mt-6 text-xl font-semibold text-gray-800 ">Why Asendia?</h3>
        <p className="mt-2 text-base leading-7 text-gray-700 ">
          In envisioning our platform, we considered the journey every individual undertakes in their career. Every career path begins with a single step, fueled by continuous growth. "Asendia" embodies this upward trajectory, signifying progress, empowerment, and reaching new heights. For job seekers, Asendia AI serves as the ladder to their dream roles, equipping them with insights, feedback, and personalized AI-driven tools to refine their profiles and interview skills. For companies, Asendia AI represents the peak they aim to conquer, delivering exceptional talent perfectly matched to their needs with precision and reliability.
        </p>

        <h3 className="mt-6 text-xl font-semibold text-gray-800 ">The Mission Within the Name</h3>
        <p className="mt-2 text-base leading-7 text-gray-700 ">
          At its core, "Asendia" reflects our dual mission:
        </p>
        <ul className="list-disc list-inside text-base leading-7 text-gray-700  mt-2">
          <li><strong>Elevating Individuals:</strong> We empower job seekers to build and improve their profiles, perfect their skills, and showcase their true potential, helping them climb their career ladder with confidence and clarity.</li>
          <li><strong>Empowering Excellence:</strong> For companies, our platform becomes a gateway to unparalleled talent. We’re not just finding good candidates; we’re uncovering the best of the best—the talent that drives businesses to excel.</li>
        </ul>
        <p className="mt-2 text-base leading-7 text-gray-700 ">
          The "AI" in our name represents the cutting-edge technology we leverage to bring this mission to life. With the power of artificial intelligence, we’re transforming the traditional hiring process into a seamless, insightful, and effective journey for everyone involved.
        </p>

        <h3 className="mt-6 text-xl font-semibold text-gray-800 ">How It Relates to Our Mission</h3>
        <p className="mt-2 text-base leading-7 text-gray-700 ">
          The ascent we speak of isn’t just about individuals rising in their careers or companies finding better talent—it’s about creating a mutually beneficial ecosystem.
        </p>
        <p className="mt-2 text-base leading-7 text-gray-700 ">
          For job seekers, Asendia AI is a partner in their journey, giving them the confidence and tools to aim higher. Soon, every job seeker will have a champion personal assistant that guides them through the process.
        </p>
        <p className="mt-2 text-base leading-7 text-gray-700 ">
          For employers, Asendia AI is a trusted guide, helping them ascend by building teams that redefine excellence. Soon, every recruiter will have a champion assistant that takes over all the redundant tasks.
        </p>
        <p className="mt-2 text-base leading-7 text-gray-700 ">
          We’re not just facilitating connections; we’re building pathways for growth and success, redefining what’s possible for both talent and companies.
        </p>

        <h3 className="mt-6 text-xl font-semibold text-gray-800 ">A Symbol of Trust and Aspiration</h3>
        <p className="mt-2 text-base leading-7 text-gray-700 ">
          "Asendia AI" stands as a beacon of trust, ambition, and potential. It inspires belief in the power of growth—whether personal, professional, or organizational. It reflects our belief that every person deserves the chance to rise, and every company deserves access to the talent that will take them to the next level.
        </p>

        <h3 className="mt-6 text-xl font-semibold text-gray-800 ">The Promise of Asendia</h3>
        <p className="mt-2 text-base leading-7 text-gray-700 ">
          When people hear "Asendia AI," we want them to feel inspired and empowered. It’s not just a name; it’s a movement. A commitment to making the hiring process more inclusive, more human, and more transformative than ever before. At Asendia AI, we don’t just match talent to companies; we elevate them—helping them achieve their peak potential and transforming the way careers and companies grow.
        </p>
        <p className="mt-2 text-base leading-7 text-gray-700 ">
          That’s the story of Asendia AI: a name rooted in elevation, driven by excellence, and built for a world where everyone rises together.
        </p>

        <h2 className="mt-8 text-2xl font-semibold text-gray-900 ">Our Impact in Just 3 Weeks</h2>
        <ul className="list-disc list-inside text-base leading-7 text-gray-700  mt-2">
          <li>✅ 5K+ users unlocking potential</li>
          <li>✅ 6K+ sessions, 25K+ activities completed</li>
          <li>✅ 15K minutes of AI-powered interview prep</li>
          <li>✅ A global reach 🌍</li>
          <li>✅ Honored as Product of the Day (#2) on Product Hunt 🎉</li>
        </ul>

        <h2 className="mt-8 text-2xl font-semibold text-gray-900 ">Join Us on This Journey</h2>
        <p className="mt-4 text-base leading-7 text-gray-700 ">
          But here's the thing: We're not doing this alone. We believe in the power of collaboration. Right before the official launch, we’ve been operating under beta with several strong design partners, and we’re now inviting more partners to join us.
        </p>
        <p className="mt-4 text-base leading-7 text-gray-700 ">
          We're seeking partners who share our vision. If you're ready to join forces, we want to hear from you! You can sign up for our partner program <a href="https://www.asendia.ai/partners/partner-contact" className="text-indigo-600 underline">here</a>.
        </p>
        <p className="mt-4 text-base leading-7 text-gray-700 ">
          Our journey is just beginning, and we’re excited to have you onboard. Curious to learn more about partnering with us? Reach out and let's make magic happen!
        </p>
        <p className="mt-4 text-base leading-7 text-gray-700 ">
          <strong>P.S.</strong><br />
          Want to see how Asendia AI can elevate your business? <a href="https://www.asendia.ai/talk-to-founders" className="text-indigo-600 underline">Book a demo here</a>.
        </p>
      </>
    ),
    relatedArticles: [
      {
        id: 'ai-agents-revolutionizing-recruitment',
        title: 'From Chaos to Clarity: How AI Agents Are Helping Recruiters Save Time, Cut Costs, and Find the Best Talent Faster',
        imgSrc: 'https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2Ff48f6697-17b0-42d8-815c-eeba2dcfc249_1128x686.png',
        readTime: 'Read in 5 minutes',
      },
    ],
  }
};

const BlogDetail = () => {
  const { id } = useParams();
  const post = blogContent[id];

  // State to handle dropdown visibility for comments (if applicable)
  const [dropdownOpen, setDropdownOpen] = useState({});

  const toggleDropdown = (commentId) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [commentId]: !prevState[commentId],
    }));
  };

  if (!post) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100 ">
        <p className="text-xl text-gray-700 ">Blog post not found.</p>
      </div>
    );
  }

  return (
    <>
      {/* Meta Tags for SEO and Social Sharing */}
      <Helmet>
        <title>{post.title} ─ Asendia AI</title>
        <meta name="description" content={post.description} />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={`${post.title} - Asendia AI`} />
        <meta property="og:description" content={post.description} />
        <meta property="og:image" content={post.mainImage} />
        <meta property="og:url" content={`https://asendia.ai/blog/${id}`} />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${post.title} - Asendia AI`} />
        <meta name="twitter:description" content={post.description} />
        <meta name="twitter:image" content={post.mainImage} />

        {/* JSON-LD Structured Data */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              "headline": "${post.title}",
              "description": "${post.description}",
              "image": "${post.mainImage}",
              "author": {
                "@type": "Person",
                "name": "${post.author.name}",
                "image": "${post.author.imgSrc}"
              },
              "datePublished": "${new Date(post.author.date).toISOString()}",
              "publisher": {
                "@type": "Organization",
                "name": "Asendia AI",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://asendia.ai.com/image/asendia_logo_icon.png" // Replace with your logo URL
                }
              }
            }
          `}
        </script>
      </Helmet>

      {/* Main Content */}
      <main className="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white antialiased">
        <div className="px-4 mx-auto max-w-screen-xl">
          <article className="mx-auto w-full max-w-[52rem]">
            {/* Header */}
            <header className="mb-8">
              <h1 className="mb-4 text-3xl font-normal leading-tight text-center text-gray-900 lg:mb-6 lg:text-5xl ">
                {post.title}
              </h1>
              <p className="text-base text-gray-500 text-center">
                <span className="inline-block bg-indigo-200/50 text-indigo-700 rounded-full px-3 py-1 text-sm">
                  <time dateTime={new Date(post.author.date).toISOString()}>{post.author.date}</time>
                </span>
              </p>
            </header>
            {/* End Header */}

            {/* Blog Content */}
            <div className="prose max-w-none">
              {post.content}
            </div>
            {/* End Blog Content */}

            {/* Author Footer */}
            <footer className="mt-12 border-t border-gray-100 pt-6">
              <div className="flex items-center">
                <img
                  className="w-14 h-14 rounded-full mr-4"
                  src={post.author.imgSrc}
                  alt={post.author.name}
                />
                <div>
                  <a href="#" rel="author" className="text-xl font-normal text-gray-900 ">
                    {post.author.name}
                  </a>
                  <p className="text-base text-gray-400 ">{post.author.role}</p>
                </div>
              </div>
            </footer>
            {/* End Author Footer */}
          </article>
        </div>
      </main>
      {/* End Main Content */}

      {/* Related Articles */}
      <aside aria-label="Related articles" className="py-8 lg:py-24 bg-gray-50 ">
        <div className="px-4 mx-auto max-w-screen-xl">
          <h2 className="mb-8 text-2xl font-bold text-gray-900 ">Related articles</h2>
          <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-4">
            {post.relatedArticles.map((related) => (
              <article key={related.id} className="max-w-xs">
                <Link to={`/blogs/${related.id}`}>
                  <img src={related.imgSrc} className="mb-5 rounded-3xl w-full h-48 object-cover" alt={related.title} />
                </Link>
                <h3 className="mb-2 text-xl font-bold text-gray-900 ">
                  <Link to={`/blogs/${related.id}`} className="hover:underline">
                    {related.title}
                  </Link>
                </h3>
                <p className="mb-4 text-gray-500 ">
                  Discover tips and strategies for enterprise design to enhance your business solutions.
                </p>
                <Link
                  to={`/blogs/${related.id}`}
                  className="inline-flex items-center font-medium underline underline-offset-4 text-indigo-600 hover:no-underline"
                >
                  {related.readTime}
                </Link>
              </article>
            ))}
          </div>
        </div>
      </aside>
      {/* End Related Articles */}

      {/* Newsletter Section */}
      <div className='max-w-[1340px] h-auto mt-10 mx-auto flex flex-col max-sm:p-[10px]'>
        <NewsletterSection />
      </div>
      {/* End Newsletter Section */}
    </>
  );
};

export default BlogDetail;
