export const JobsData = [
    {
        title: 'Junior Software Engineer',
        slug: 'junior-software-engineer',
        type: 'Part-time',
        salary: '$50 - $100 per hour (based on experience)',
        location: 'Remote (Applicants from the U.S., UK, and Canada)',
        hours: '10–20 hours per week (with potential to increase to 40)',
        sections: [
            {
                title: 'About the Role',
                paragraphs: [
                    "Step into a dynamic, creative environment where AI innovation thrives. We're working with leading AI companies in the UK, US, and Canada on exciting projects pushing the boundaries of technology. As a Junior Software Engineer, you'll have the opportunity to test, evaluate, and improve cutting-edge AI models in ways that make a real-world impact.",
                    "This role isn't just about running tests—it’s about experimenting with ideas, refining solutions, and being part of the AI evolution. You’ll be part of a team that values creativity, collaboration, and continuous learning."
                ]
            },
            {
                title: 'Our Simple Selection Process',
                bullets: [
                    "Apply: Submit your latest resume.",
                    "Mock Interview: Participate in a quick mock interview to showcase your skills. (No Coding Needed)",
                    "Decision: We’ll review your application and notify you of the outcome within a few weeks."
                ]
            },
            {
                title: 'Who You Are',
                bullets: [
                    "A degree in Computer Science or related field from a university in the U.S., UK, or Canada.",
                    "1+ years of software engineering experience (including internships).",
                    "A curious mind that thrives on problem-solving and exploration.",
                    "Strong attention to detail and excellent communication skills."
                ]
            },
            {
                title: 'Compensation & Work Setup',
                paragraphs: [
                    "Pay: $50–$100/hour based on experience.",
                    "Contract: Hourly, with weekly payments via Stripe.",
                    "Hours: 10–20 hours per week (with room to increase to 40 hours).",
                    "Start Date: Immediate upon selection."
                ]
            },
            {
                title: 'Why You’ll Love Working Here',
                bullets: [
                    "Creative Work Culture: Your ideas and fresh perspectives are encouraged.",
                    "Impactful Projects: You'll be working on technology that affects how businesses operate and innovate.",
                    "Flexibility: Set your own schedule and work at your pace, fully remote.",
                    "Growth Potential: Learn from experienced tech teams and gain hands-on experience in building tomorrow's tech."
                ]
            },
            {
                title: 'Refer a Friend, Earn Big! 🚀',
                paragraphs: [
                    "Got a friend, colleague, or connection who would crush this role? Don’t keep it to yourself! Send them our way and earn $300 for every successful referral."
                ]
            }
        ]
    },
    {
        title: 'Founding Engineer',
        slug: 'founding-engineer',
        type: 'Full-time',
        salary: '$80K – $170K + stock awards (ESOP)',
        location: 'Remote (Startups based in the U.S. and UK)',
        hours: 'Full-time',
        sections: [
            {
                title: 'About the Role',
                paragraphs: [
                    "Step into a pivotal role at an early-stage startup where innovation and AI intersect. We're partnering with some of the fastest-growing companies in the U.S. and UK that have just raised Seed to Series A funding. These startups are developing AI-driven solutions across industries such as Fintech, HRTech, and EdTech.",
                    "As a Founding Engineer, you’ll take full ownership of technical development and work directly with startup founders to shape product and technology strategies. You’ll have the opportunity to define the product roadmap, build scalable infrastructure, and make a significant impact on each startup’s success. We’re looking for someone who’s excited to work in a fast-moving environment, thrives on solving complex challenges, and embraces responsibility and ownership."
                ]
            },
            {
                title: 'You Are a Good Fit If You:',
                bullets: [
                    "Have deep expertise in one or more of the following: Python, Node.js, TypeScript, React, or other modern tech stacks.",
                    "Have 3+ years of industry experience working directly in full-stack development (or equivalent experience in startup or product-focused roles).",
                    "Have experience with cloud platforms (Azure preferred, AWS or GCP acceptable), microservices, and Kubernetes."
                ]
            },
            {
                title: 'Compensation & Benefits',
                paragraphs: [
                    "Salary: $80K – $170K based on experience.",
                    "Stock Awards: Equity via ESOP, allowing you to share in the startups’ long-term success.",
                    "Location: Fully remote role working with U.S. and UK-based startups.",
                    "Start Date: Immediate upon selection."
                ]
            },
            {
                title: "Why You'll Love Working Here",
                bullets: [
                    "High-Impact Work: Build products that directly shape the success of early-stage startups and their technology.",
                    "Ownership: Drive technical decisions and product direction at the foundational stage of multiple startups.",
                    "Growth Potential: Collaborate with startup founders and advance your career through exposure to cutting-edge AI and scalable systems.",
                    "Flexible Work: Fully remote, with autonomy over your schedule and work style.",
                    "Creative Culture: Work with dynamic, fast-moving teams that value creativity, innovation, and learning."
                ]
            },
            {
                title: 'Our Simple Selection Process',
                bullets: [
                    "Apply: Submit your latest resume.",
                    "Mock Interview: Participate in a quick mock interview to showcase your skills. (No Coding Needed)",
                    "Decision: We’ll review your application and notify you of the outcome within a few weeks."
                ]
            },
            {
                title: 'Refer a Friend, Earn Big! 🚀',
                paragraphs: [
                    "Know someone who’d thrive in this role? Refer them and earn $500 for every successful hire!"
                ]
            }
        ]
    },
    {
        title: 'Business Strategy Consultant',
        slug: 'business-strategy-consultant',
        type: 'Part-time',
        salary: '$50 – $100 per hour (based on experience)',
        location: 'Remote (Applicants from the U.S., UK, and Canada)',
        hours: '10–20 hours per week (with potential for more based on project needs)',
        sections: [
            {
                title: 'About the Role',
                paragraphs: [
                    "We’re hiring MBA students and graduates to partner with top AI companies and startups in the U.S., UK, and Canada. This role offers $50–100 USD/hour with flexible, remote work.",
                    "You’ll support founders and business leaders by providing strategic insights, market research, and process improvements, helping shape next-generation AI solutions."
                ]
            },
            {
                title: 'You Might Be a Great Fit If You:',
                bullets: [
                    "Have worked in roles like: Strategy or Management Consulting, Corporate Finance/Investment Banking, Operations/Business Strategy, Marketing/Sales Leadership, Business Development, Recruitment or Talent Strategy, Project or Program Management.",
                    "Are skilled at conducting in-depth research, analyzing data, and crafting strategic recommendations.",
                    "Excel at working across teams in fast-paced, innovative environments."
                ]
            },
            {
                title: 'What to Expect',
                paragraphs: [
                    "Collaborate with AI experts and startup founders on high-impact projects.",
                    "Work is remote, asynchronous, and flexible (10–20 hours/week).",
                    "Receive weekly payouts through Stripe."
                ]
            },
            {
                title: 'Refer a Friend, Earn Big! 🚀',
                paragraphs: [
                    "Know someone who’d thrive in this role? Refer them and earn $300 for every successful hire!"
                ]
            }
        ]
    },
    {
        title: 'Student Opportunities – (Internship)',
        slug: 'student-opportunities-internship',
        type: 'Part & Full-Time',
        salary: '$15 – $50 per hour (based on experience)',
        location: 'Remote (Applicants from the U.S., UK, and Canada)',
        hours: '10–40 hours per week',
        sections: [
            {
                title: 'About the Role',
                paragraphs: [
                    "We’re working with top AI companies and startups in the U.S., UK, and Canada. As an intern, you’ll gain hands-on experience in software development, contributing to real-world AI projects. You’ll collaborate with experienced engineers to build, test, and improve innovative technologies."
                ]
            },
            {
                title: 'You Might Be a Great Fit If You:',
                bullets: [
                    "Are a student or recent graduate in Computer Science or a related field.",
                    "Have basic coding experience (Python, JavaScript, or other programming languages).",
                    "Are eager to learn and curious about AI."
                ]
            },
            {
                title: 'Role Details',
                bullets: [
                    "Write code, test features, and work on development tasks.",
                    "Work is remote and flexible (10–40 hours per week).",
                    "Project length is 1–2 months with possible extensions.",
                    "Payments are made weekly via Stripe Connect."
                ]
            },
            {
                title: 'Refer a Friend, Earn Big! 🚀',
                paragraphs: [
                    "Know someone who’d thrive in this role? Refer them and earn $125 for every successful hire!"
                ]
            }
        ]
    }
];